function nodeListForEach() {
    if (window.NodeList && !NodeList.prototype.forEach) {
        return NodeList.prototype.forEach = Array.prototype.forEach;
    }
}

function isNaN() {
    return Number.isNaN = Number.isNaN || (value => value !== value); // eslint-disable-line
}

function matches() {
    if (Element.prototype.matches) {
        return;
    }
    return Element.prototype.matches = Element.prototype.msMatchesSelector;
}

function closest() {
    if (Element.prototype.closest) {
        return;
    }

    return Element.prototype.closest = function (cssSelector) {
        let node = this;

        while (node) {
            if (node.matches(cssSelector)) {
                return node;
            }

            node = node.parentElement;
        }

        return null;
    };
}

function customEvents() {
    // DOM Level 3 compatibility for Events
    if (typeof window.CustomEvent === 'function') {
        return;
    }

    function CustomEvent(name, params) {
        let eventParams = params || { bubbles: false, cancelable: false, detail: undefined };

        const event = document.createEvent('CustomEvent');
        event.initCustomEvent(
            name, eventParams.bubbles,
            eventParams.cancelable,
            eventParams.detail
        );

        return event;
    }

    window.CustomEvent = CustomEvent;
}

module.exports = function () {
    nodeListForEach();
    customEvents();
    isNaN();
    matches();
    closest();
};
