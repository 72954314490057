const PartialOverlay = class PartialOverlay {
    /**
     * This component serve as single tone of overlay for Search Combo and Global Menu.
     * The specific of this component is that it not cover header part of the site so
     * it looks like popups brings this overlay.
     * @param {Node} domNode
     */
    constructor(domNode) {
        this.overlay = domNode;
        this.handlers = [];

        this.visibleClassName = 'b-overlay-visible';
        this.hidingClassName = 'm-hiding';
        this.timeOut = 300;
        this.bodyClasses = document.body.classList;
    }

    _addEventListeners(handler) {
        this.overlay.addEventListener('click', handler);
        this.handlers.push(handler);
    }

    _removeEventListeners() {
        this.handlers.forEach(handler => this.overlay.removeEventListener('click', handler));
        this.handlers = [];
    }

    _addClasses() {
        this.bodyClasses.remove(this.hidingClassName);
        this.bodyClasses.add(this.visibleClassName);
    }

    _removeClasses() {
        this.bodyClasses.remove(this.visibleClassName);
    }

    _setHandler(clickHandler) {
        if (
            typeof clickHandler === 'function' &&
            this.handlers.indexOf(clickHandler) === -1
        ) {
            this._addEventListeners(clickHandler);
        }
    }

    open(clickHandler) {
        this._setHandler(clickHandler);
        this._addClasses();
        clearTimeout(this.timer);
    }

    close() {
        this.bodyClasses.add(this.hidingClassName);
        clearTimeout(this.timer); // clear previous timer and assign new
        this.timer = setTimeout(() => this._removeClasses(), this.timeOut);
        this._removeEventListeners();
    }

    destroy() {
        this._removeEventListeners();
        this._removeClasses();
        delete window.partialOverlay;
    }
};

module.exports = function () {
    const overlayNode = document.querySelector('[data-js-overlay]');
    window.partialOverlay = new PartialOverlay(overlayNode);
};
