module.exports = class NewNav {

    constructor(domeNode) {
        this.domeNode = domeNode;
        this.backBtn = this.domeNode.querySelector('[data-js-nav-back]');
        this.body = document.body;
        this.windowWidth = document.documentElement.clientWidth;
        this.isFirstClick = true;

        this.trackCurrentLevel = 0;
        this.activePanels = [];

        this.forward = this.forward.bind(this);
        this.back = this.back.bind(this);
    }

    init() {
        this.initEventListeners();
    }

    initEventListeners() {
        this.resetHamburgerState = this.resetHamburgerState.bind(this);
        const hamburgerPanel = document.getElementById('hamburger-menu');
        const header = document.querySelector('.l-header');
        const content = document.querySelector('.l-content');
        const utilityMenu = document.querySelector('.b-utility_domain_switcher');
        const desktopBar = document.querySelector('.b-desktop_bar');
        const desktopBtn = document.querySelector('#toggleDesktop');

        this.domeNode.addEventListener('click', this.forward.bind(this));
        this.backBtn.addEventListener('click', this.back.bind(this));
        hamburgerPanel.addEventListener('dialog:closed', this.resetHamburgerState);
        header.addEventListener('click', this.resetHamburgerState);
        content.addEventListener('click', this.resetHamburgerState);
        utilityMenu.addEventListener('click', this.resetHamburgerState);

        const isToggled = desktopBtn.classList.contains('m-toggled');

        if (this.windowWidth > 1199) {
            desktopBar.addEventListener('click', (e) => {
                if (!e.target.closest('.n-desktop-btn')) {
                    this.resetHamburgerState();
                }
            });

            desktopBtn.addEventListener('click', () => {
                if (this.isFirstClick && !isToggled) {
                    this.isFirstClick = false;
                } else {
                    this.resetHamburgerState();
                }
            });
        }
    }

    forward(e) {
        let clickedLink = e.target;
        if (clickedLink.parentElement.tagName === 'A') {
            clickedLink = clickedLink.parentElement;
        }
        if (!clickedLink.hasAttribute('data-js-nav-to') ||
            !clickedLink.nextElementSibling ||
            !clickedLink.nextElementSibling.hasAttribute('data-js-nav-flyout')) {
            return;
        }

        e.preventDefault();

        this.moveTrackTo(parseInt(clickedLink.getAttribute('data-js-nav-to'), 10));
        this.activatePanel(clickedLink);

    }

    back(e) {
        e.preventDefault();

        if (this.trackCurrentLevel > 0) {
            this.moveTrackTo((this.trackCurrentLevel !== 9) ? this.trackCurrentLevel - 1 : 0);
        }
    }


    moveTrackTo(level) {
        const mainNavClasses = this.domeNode.classList;
        mainNavClasses.remove(`m-active_level_${this.trackCurrentLevel}`);
        mainNavClasses.add(`m-active_level_${level}`);
        this.trackCurrentLevel = level;
        this.domeNode.scrollTop = 0;
        this.toggleBackButton();
    }

    activatePanel(activeLink) {
        const panelRelatedToLink = activeLink.nextElementSibling;

        if (this.activePanels[this.trackCurrentLevel]) {
            this.activePanels[this.trackCurrentLevel].classList.remove('m-active');
        }

        panelRelatedToLink.classList.add('m-active');
        this.activePanels[this.trackCurrentLevel] = panelRelatedToLink;
    }

    toggleBackButton() {
        const backButtonClasses = this.backBtn.classList;

        if (this.trackCurrentLevel === 0 || this.trackCurrentLevel === 9) {
            backButtonClasses.remove('m-activated');
        } else {
            backButtonClasses.add('m-activated');
        }
    }

    /**
     * Resets the state of the hamburger menu
     */
    resetHamburgerState() {
        this.isFirstClick = true;

        setTimeout(() => {
            this.resetClasses();
            this.currentLevel = 0;
            this.openedPanel = null;
            this.backBtn.classList.remove('m-activated');

            if (this.windowWidth > 1199) {
                this.body.classList.remove('has-dialog');
            }
        }, 400); // 400 panel animation delay
    }

    resetDesktopBar() {
        const hamburgerMenu = document.querySelector('.n-hamburger');
        const hamburgerMobileContainer = document.querySelector('.mobile-container');
        const desktopHamburgerButton = document.querySelector('[data-js-toggle-hamburger-desktop]');
        desktopHamburgerButton.classList.remove('m-toggled');
        if (hamburgerMenu.closest('.hamburger-container')) {
            hamburgerMobileContainer.append(hamburgerMenu);
        }
    }

    resetClasses() {
        const nMainMenu = document.querySelector('.n-main_menu');
        const nPanel = document.querySelector('#hamburger-menu');
        this.domeNode.classList.remove(`m-active_level_${this.trackCurrentLevel}`);
        this.activePanels.forEach(panel => panel.classList.remove('m-active'));

        if (window.innerWidth < 1200) {
            nMainMenu.style.removeProperty('height');
            nPanel.style.removeProperty('height');
        }

        this.resetDesktopBar();
    }

    destroy() {
        this.domeNode.removeEventListener('click', this.forward);
        this.backBtn.removeEventListener('click', this.back);
        document.body.removeEventListener('hamburger:close', this.resetHamburgerState);
        this.resetClasses();
    }
};
