module.exports = function () {
    const $scrollToTopButton = $('#scrollToTop');
    var timeout = false;

    $(window).scroll(() => {

        if (timeout !== false) {
            clearTimeout(timeout);

            if ($(window).scrollTop() > 200) {
                $scrollToTopButton.addClass('show');
            } else {
                $scrollToTopButton.removeClass('show');
            }
        }

        timeout = setTimeout(() => {
            $scrollToTopButton.removeClass('show');
        }, 800);
    });

    $scrollToTopButton.on('click', (e) => {
        e.preventDefault();
        $('html, body').animate({scrollTop: 0}, '500');
    });
};
