module.exports = function(object) {
    const domNode = object instanceof jQuery ? object[0] : object;
    const position = domNode ? domNode.getBoundingClientRect().top + window.scrollY : 0;
    const container = window;

    if (typeof container.scrollTo !== 'function') {
        domNode.scrollIntoView();
    }

    container.scrollTo({
        top: position,
        left: 0,
        behavior: 'smooth'
    });
};
