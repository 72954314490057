// eslint-disable-next-line no-multi-assign
window.jQuery = window.$ = require('jquery');
const processInclude = require('./util');
require('./components/polyfills.js')();
const scripts = $('#main_scripts').data('scripts') || [];

scripts.reduce((promise, script) => {
    return promise.then(() => new Promise((resolve) => {
        $.ajax({
            dataType: 'script',
            crossDomain: true,
            cache: true,
            url: script
        }).always(resolve);
    }));
}, Promise.resolve());

require('slick-carousel');
require('./components/jqueryZoom');
require('./components/spinner');

$(document).ready(() => {
    processInclude(require('./components/PartialOverlay'));
    processInclude(require('./components/pageHeaderAd'));
    processInclude(require('./components/cookieModal'));
    processInclude(require('./components/menu'));
    processInclude(require('./components/dialogs/dialogs'));
    processInclude(require('./components/a11y/a11y-helpers'));
    processInclude(require('./components/popupButton'));
    processInclude(require('./components/consentTracking'));
    processInclude(require('./components/mailSignupForm'));
    processInclude(require('./cart/removeProduct'));
    processInclude(require('./components/miniCart'));
    processInclude(require('./components/postalCodeMenu'));
    processInclude(require('./components/footerNav'));
    processInclude(require('./components/scrollToTop'));
    processInclude(require('./components/headerSearch'));
    processInclude(require('./components/barcode'));
    processInclude(require('./components/clientSideValidation'));
    processInclude(require('./components/countrySelector'));
    processInclude(require('./components/toggleHamburger'));
    processInclude(require('./components/stickyHeaderHeight'));
    processInclude(require('./components/breadcrumbs'));
    processInclude(require('./components/a11y/Notifier'));
    processInclude(require('./components/inputFormaters'));
    processInclude(require('./components/dataLayerGlobal'));
    processInclude(require('./myaccount/sessionTimeout'));
    processInclude(require('./giftcard/giftcard.js'));
    processInclude(require('./slickCarousel.js'));
    processInclude(require('./storeLocatorModal.js'));
    processInclude(require('../js/product/wishlistHeart.js'));
    processInclude(require('./components/accountModalMobile'));
    processInclude(require('./components/newsletterModal'));
    processInclude(require('./subscription/subscribeRoomsAndSpaces'));
    processInclude(require('./components/domainswitch'));
});
