/**
 * Remove validation.
 * @param {element} field - field to be cleared
 */
function clearField(field) {
    $(field)
        .removeClass('is-invalid')
        .siblings('.invalid-feedback')
        .hide()
        .html('');
}

function focusOnFirstError(form) {
    for (let i = 0; i < form.length; i++) {
        if (!form[i].validity.valid) {
            form[i].focus();
            break;
        }
    }
}
/**
 * Remove all validation. Should be called every time before revalidating form
 * @param {element} form - Form to be cleared
 * @returns {void}
 */
function clearForm(form) {
    $(form)
        .find('.form-control.is-invalid')
        .removeClass('is-invalid')
        .siblings('.invalid-feedback')
        .hide()
        .html('');
}

/**
 * Validate whole form. Requires `this` to be set to form object
 * @param {jQuery.event} event - Event to be canceled if form is invalid.
 * @returns {boolean} - Flag to indicate if form is valid
 */
function validateForm(event) {
    let valid = true;
    if (this.checkValidity && !this.checkValidity()) {
        // safari
        if (event) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }
        $(this).find('input, select').each(function () {
            if (!this.validity.valid) {
                valid = false;
                $(this).trigger('invalid', this.validity);
            }
        });
    }
    return valid;
}
/**
 * Toggles the subscription button based on the conditions and privacy checkbox and subscription options
 */
function toggleSubscriptionButton() {
    var $subscriptionBtn = $('.js-confirm-subscription-button');

    var subscriptionOptionsExist = $('.js-subscription-list-checkbox').length > 0;
    var conditionsAndPrivacyChecked = $('.js-conditions-and-privacy:checked').length !== 0;

    if (conditionsAndPrivacyChecked) {
        if (subscriptionOptionsExist) {
            var subscriptionOptionsSelected = $('.js-subscription-list-checkbox:checked').length !== 0;
            $subscriptionBtn.prop('disabled', !subscriptionOptionsSelected);
        } else {
            $subscriptionBtn.prop('disabled', false);
        }
    } else {
        $subscriptionBtn.prop('disabled', true);
    }
}
function validateEmailField(targetElement) {
    const $targetElement = $(targetElement);
    const $newEmailField = $('.js-new-email');

    if ($targetElement.hasClass('js-emailId')) {
        if ($targetElement.val().match(/"|<|>/)) {
            $targetElement[0].setCustomValidity($targetElement.data('pattern-mismatch-custom'));
            $targetElement.addClass('is-invalid');
        } else if ($targetElement.hasClass('js-confirm-email')) {
            if ($newEmailField.val() !== $targetElement.val() && $targetElement.data('confirmemail-mismatch')) {
                $targetElement[0].setCustomValidity($targetElement.data('confirmemail-mismatch'));
                $newEmailField.addClass('is-invalid');
                $targetElement.addClass('is-invalid');
            } else {
                $newEmailField.removeClass('is-invalid');
                $targetElement.removeClass('is-invalid');
            }
        } else {
            $targetElement[0].setCustomValidity('');
            $newEmailField.removeClass('is-invalid');
            $targetElement.removeClass('is-invalid');
        }
    }
}
/**
 * Validate field immediate after blur
 */
function realTimeValidationForm() { // eslint-disable-line complexity
    const $targetElement = $(this);

    validateEmailField(this);

    if ($targetElement.hasClass('js-agreement')) {
        $('.js-dataLayer-account-sign-in').attr('disabled', !$targetElement.prop('checked'));
    }

    if ($targetElement.hasClass('js-input-trim')) {
        $targetElement.val($targetElement.val().trim());
    }

    if ($targetElement.attr('type') === 'password' && $targetElement.hasClass('js-confirm-password')) {
        const $newPasswordField = $('.js-new-password:visible');
        const newPasswordElementValue = $newPasswordField.val();
        if (newPasswordElementValue !== $targetElement.val() && $targetElement.data('confirmpassword-mismatch')) {
            $targetElement[0].setCustomValidity($targetElement.data('confirmpassword-mismatch'));
            $newPasswordField.addClass('is-invalid');
            $targetElement.addClass('is-invalid');
        } else {
            $targetElement[0].setCustomValidity('');
            $newPasswordField.removeClass('is-invalid');
            $targetElement.removeClass('is-invalid');
        }
    }

    if ($targetElement.hasClass('js-subscription-list-checkbox')) {
        const $otherSubscriptionListsCheckboxes = $('.js-subscription-list-checkbox').not($targetElement);
        const $otherSubscriptionListsCheckboxesChecked = $('.js-subscription-list-checkbox:checked').not($targetElement);

        $otherSubscriptionListsCheckboxes.prop(
            'required',
            !$targetElement.prop('checked') && ($otherSubscriptionListsCheckboxesChecked.length <= 1)
        );
    }

    if ($targetElement.hasClass('js-subscription-list-checkbox') || $targetElement.hasClass('js-conditions-and-privacy')) {
        toggleSubscriptionButton();
    }
    if ($targetElement.hasClass('js-textarea-with-range-error')) {
        if ((Number($targetElement.attr('maxlength')) === Number($targetElement[0].textLength))) {
            $targetElement.addClass('is-invalid');
            $targetElement.parents('.form-group').find('.invalid-feedback')
                .text($targetElement.data('range-error')).show();
        } else {
            $targetElement.parents('.form-group').find('.invalid-feedback')
                .text('').show();
            $targetElement.removeClass('is-invalid');
        }
        return;
    }

    if ($targetElement.hasClass('l-checkout-gift_message-area')) {
        const regex = /(\p{Emoji_Presentation}|[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}])/gu;

        if (regex.test(this.value)) {
            $targetElement.addClass('is-invalid');
            $targetElement.parents('.form-group').find('.invalid-feedback').text($targetElement.data('pattern-mismatch')).show();
        } else {
            $targetElement.parents('.form-group').find('.invalid-feedback').text('').show();
            $targetElement.removeClass('is-invalid');
        }

        return;
    }

    if (!this.validity.valid) {
        $(this).trigger('invalid', this.validity);
    } else {
        if ($(this).is(':not(#js-shippingAddressTwo)')) {
            clearField(this);
        }
        if ($(this).hasClass('js-subscription-list-checkbox')) {
            clearField($('.js-subscription-list-checkbox'));
        }
    }
}

module.exports = {
    init() {
        this.invalid();
        this.submit();
        this.realTimeValidation();
        this.buttonClick();
        this.clearModalForms();
    },

    invalid() {
        const invalidateFunction = function (e) { // eslint-disable-line complexity
            e.preventDefault();
            if ($(e.currentTarget).hasClass('js-conditions-and-privacy')) {
                toggleSubscriptionButton();
            }
            if ($(this).hasClass('js-confirm-password')) {
                const validationMessage = $(this).data('confirmpassword-mismatch');
                $(this).parents('.form-group').find('.invalid-feedback')
                       .text(validationMessage).show();
            }

            if ($(this).hasClass('js-emailId')) {
                const validationMessage = $(this).data('pattern-mismatch-custom');
                $(this).parents('.form-group').find('.invalid-feedback')
                       .text(validationMessage).show();
            }

            this.setCustomValidity('');

            if (!this.validity.valid) {
                let { validationMessage } = this;
                $(this).addClass('is-invalid');
                if (this.validity.patternMismatch && $(this).data('pattern-mismatch')) {
                    validationMessage = $(this).data('pattern-mismatch');
                }

                // Seems like ranges validation works only on type number
                if (
                    (this.validity.rangeOverflow || this.validity.rangeUnderflow) &&
                    $(this).data('range-error')
                ) {
                    validationMessage = $(this).data('range-error');
                }
                if (
                    (this.validity.tooLong || this.validity.tooShort) &&
                    $(this).data('range-error')
                ) {
                    validationMessage = $(this).data('range-error');
                }
                if (this.validity.valueMissing && $(this).data('missing-error')) {
                    validationMessage = $(this).data('missing-error');
                }
                if (this.validity.typeMismatch && $(this).data('type-mismatch')) {
                    validationMessage = $(this).data('type-mismatch');
                }
                $(this).parents('.form-group').find('.invalid-feedback')
                   .text(validationMessage).show();
            }
        };

        $(':input').on('invalid', invalidateFunction);
    },

    submit() {
        $('form').on('submit', function (e) {
            const valid = validateForm.call(this, e);

            if (!valid && $(this).data('focusOnError')) {
                focusOnFirstError(this);
            }
            return valid;
        });
    },

    realTimeValidation() {
        $('input[type!="checkbox"], select').off('invalid', realTimeValidationForm);
        $('input[type="checkbox"]').off('change', realTimeValidationForm);
        $('textarea').off('focusout', realTimeValidationForm);

        $('input[type!="checkbox"], select').on('focusout', realTimeValidationForm);
        $('input[type="checkbox"]').on('change', realTimeValidationForm);
        $('textarea').on('focusout', realTimeValidationForm);
    },

    buttonClick() {
        $('form button[type="submit"], form input[type="submit"]').on('click', function () {
            // clear all errors when trying to submit the form
            clearForm($(this).parents('form'));
        });
    },

    clearModalForms() {
        document.addEventListener('dialog:closed', event =>
            clearForm(event.target.querySelector('form')));
    },

    functions: {
        validateForm(form, event) {
            return validateForm.call($(form)[0], event || null);
        },
        clearForm
    }
};
