const COOKIE_MODAL = 'cookieModal';
const COOKIE_MODAL_VALUE = 'closed';
const COOKIE_MODAL_TIMEOUT = 'cookieModalTimeout';

const cookieModal = {
    init() {
        this.cookieModal = document.body.querySelector('.cookie-modal');

        const timeout = Number(this.cookieModal ? this.cookieModal.dataset.timeout : 0);

        this.timeout = isNaN(timeout) ? 0 : timeout;

        if (!this.cookieModal || this.getCookie(COOKIE_MODAL) || (this.timeout && this.autoClose())) {
            return;
        }

        this.cookieModalCloseBtn = this.cookieModal.querySelector('.cookie-modal-close');

        this.showCookieModal();
        this.events();
    },

    autoClose() {
        const timeoutCookie = Number(this.getCookie(COOKIE_MODAL_TIMEOUT));
        const currentTime = (new Date()).getTime();

        if (timeoutCookie) {
            if (!isNaN(timeoutCookie) && (timeoutCookie < currentTime)) {
                this.setCookie(COOKIE_MODAL, COOKIE_MODAL_VALUE);
                this.removeCookie(COOKIE_MODAL_TIMEOUT);

                return true;
            }
        } else {
            this.setCookie(COOKIE_MODAL_TIMEOUT, currentTime + (this.timeout * 60 * 1000));
        }

        return false;
    },

    events() {
        if (this.cookieModalCloseBtn) {
            this.cookieModalCloseBtn.addEventListener('click', this.closeCookieModal.bind(this));
        }
    },

    showCookieModal() {
        this.cookieModal.classList.add('js-show');
        $('.js-sticky-footer').addClass('cookie-active');

        this.waitForEl('.helpButton', () => {
            $('.helpButton').addClass('fixed-cookie');
        });
    },

    waitForEl(selector, callback) {
        if ($(selector).length) {
            callback();
        } else {
            setTimeout(() => {
                this.waitForEl(selector, callback);
            }, 100);
        }
    },

    closeCookieModal() {
        this.cookieModal.style.bottom = `-${this.cookieModal.offsetHeight}px`;
        this.cookieModal.addEventListener('transitionend', () => this.cookieModal.classList.remove('js-show'));

        this.setCookie(COOKIE_MODAL, COOKIE_MODAL_VALUE);

        $('.helpButton').removeClass('fixed-cookie');
        $('.js-sticky-footer').removeClass('cookie-active');
    },

    getCookie(name) {
        let matches = document.cookie.match(new RegExp(`(?:^|; )${name}=([^;]*)`));

        return matches ? decodeURIComponent(matches[1]) : null;
    },

    setCookie(name, value) {
        document.cookie = `${name}=${value}; max-age=2592000; path=/;`;
    },

    removeCookie(name) {
        document.cookie = `${name}=; max-age=0; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;`;
    }
};

module.exports = function() {
    cookieModal.init();
};
