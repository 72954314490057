/**
 * Renders a modal window that will track the users consenting to accepting site tracking policy
 */
function showConsentModal() {
    const prepopulatedData = $('.js-concent-tracking');
    if (!prepopulatedData.data('caonline') || prepopulatedData.data('isconsenttrackingaccepted')) {
        return;
    }

    const urlContent = prepopulatedData.data('url');

    function onSuccess(responce) {
        const urlAccept = prepopulatedData.data('accept');
        const urlReject = prepopulatedData.data('reject');
        const textYes = prepopulatedData.data('accepttext');
        const textNo = prepopulatedData.data('rejecttext');
        const textHeader = prepopulatedData.data('heading');

        const htmlString = `
            <div
                class="modal consent-tracking"
                id="consent-tracking"
                role="dialog"
                data-isForcedToChoice="true"
                aria-labelledby="consent-tracking-title"
                aria-describedby="consent-tracking-body"
            >
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header" id="consent-tracking-title">${textHeader}</div>
                        <div class="modal-body" id="consent-tracking-body">${responce}</div>
                        <div class="modal-footer">
                            <div class="button-wrapper">
                                <button
                                    class="b-button m-condenced js-concent-button affirm"
                                    data-url="${urlAccept}"
                                    title="${textYes}"
                                >
                                    ${textYes}
                                </button>
                                <button
                                    class="b-button m-condenced m-red js-concent-button decline"
                                    data-url="${urlReject}"
                                    title="${textNo}"
                                >
                                    ${textNo}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        `;

        $('.js-concent-tracking').append(htmlString);
        window.dialogManager.openDialog('modal', '#consent-tracking', document.body);
    }

    // get content
    $.ajax({
        url: urlContent,
        type: 'get',
        dataType: 'html',
        success(response) {
            onSuccess(response);
        },
        error() {

        }
    });

    prepopulatedData.on('click', '.js-concent-button', function (e) {
        e.preventDefault();
        const url = $(this).data('url');

        $.ajax({
            url,
            type: 'get',
            dataType: 'json',
            success(data) {
                window.dialogManager.closeDialog();
                window.setTimeout(() => $('#consent-tracking').remove(), 1000);
                if (data.redirectUrl) {
                    window.location.href = data.redirectUrl;
                }
            },
            error() {
                window.dialogManager.closeDialog();
                window.setTimeout(() => $('#consent-tracking').remove(), 1000);
            }
        });
    });
}

module.exports = function () {
    if ($('.consented').length === 0 && $('.js-concent-tracking').hasClass('api-true')) {
        showConsentModal();
    }
};
