const hoursTooltip = require('../components/hoursPopup');
/* globals google */

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    let newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') +
        Object.keys(params).map(key => `${key}=${encodeURIComponent(params[key])}`).join('&');

    return newUrl;
}

/**
 * Gets address location using Google Maps Geocoder
 *
 * @param {*} address
 * @returns {*} result
 */
function geocodeAddress(address) {
    // Init geocoder
    const geocoder = new google.maps.Geocoder();

    return new Promise((resolve, reject) => {
        geocoder.geocode({ address }, (results, status) => {
            if (status === 'OK') {
                resolve(results[0].geometry.location);
            } else {
                reject(new Error(address));
            }
        });
    });
}

function initExpandStoresEvent(modalId, $scope = null) {
    let selector = (modalId + ' .js-locator-store-name-wrapper').trim();
    let $stores = $scope ? $scope.find(selector) : $(selector);

    $stores.click(function (e) {
        e.preventDefault();
        var $store = $(this).closest('.js-card-body');
        $store.find('.b-locator_store-address').toggleClass('expanded');
        $store.find('.b-locator_store-right_column').toggleClass('expanded');
        $store.find('.js-select-store').toggleClass('expanded');
        $store.find('.b-locator_store-arrow').toggleClass('expanded');
    });
}

/**
 * Renders the results of the search and updates the map
 * @param {object} data - Response from the server
 */
function updateStoresResults(data) {
    const $resultsDiv = $('.results');
    const $mapDiv = $('.map-canvas');
    const hasResults = data.stores.length > 0;
    $('.js-store-locator-result-wrapper').toggleClass('h-hidden', !hasResults);
    $('.js-store-locator-no-results').toggleClass('h-hidden', hasResults);

    $resultsDiv.empty()
        .data('has-results', hasResults)
        .data('radius', data.radius)
        .data('search-key', data.searchKey);

    $mapDiv.attr('data-locations', data.locations);

    if (data.storesResultsHtml) {
        $resultsDiv.append(data.storesResultsHtml);

        const $storeInStock = $resultsDiv.find('.m-in_stock');

        if ($storeInStock.length) {
            $storeInStock
                .first()
                .closest('.js-card-body')
                .find('.js-select-store-input')
                .prop('checked', true)
                .trigger('change');

            $('.js-select-store').addClass('js-store-has-been-selected');
        }
    }
    initExpandStoresEvent('#inStoreInventoryModal');
}

/**
 * Search for stores with new zip code
 * @param {JQuery<HTMLElement>} $element - the target html element
 * @returns {boolean} false to prevent default event
 */
function search($element) {
    const inventoryBody = $element.closest('.js-store-inventory-body');
    const $dialog = inventoryBody.length
        ? inventoryBody : $element.closest('.js-store-locator-container');
    const spinner = $dialog.length ? $dialog.spinner() : $.spinner();
    spinner.start();

    const $form = $element.closest('.store-locator');
    const radius = $('.js-radius').last().find('option:selected').val();
    let url = $form.attr('action');
    const urlParams = { radius };
    const query = $form.find('[name="postalCode"]').val();
    if (query) {
        geocodeAddress(query).then((position) => {
            urlParams.lat = position.lat();
            urlParams.lng = position.lng();
            urlParams.postalCode = '';
            $.ajax({
                url,
                type: $form.attr('method'),
                data: urlParams,
                dataType: 'json',
                success(data) {
                    spinner.stop();
                    updateStoresResults(data);
                }
            });
        }).catch(() => {
            spinner.stop();
            updateStoresResults({
                stores: [],
                locations: ''
            });
            $('.js-store-locator-no-results').removeClass('h-hidden');
        });
    }

    return false;
}

function findStoreByLocation() {
    if (!navigator.geolocation) {
        return;
    }

    navigator.geolocation.getCurrentPosition(position => {
        // Hide empty query error
        $('.js-store-locator-no-results').addClass('h-hidden');
        const $detectLocationButton = $('.js-btn-find-by-location');
        const url = $detectLocationButton.data('action');
        const radius = $('.store-locator-container').find('.js-radius').val();
        const urlParams = {
            radius,
            lat: position.coords.latitude,
            lng: position.coords.longitude
        };
        $.ajax({
            url,
            type: 'get',
            dataType: 'json',
            data: urlParams,
            timeout: 6000,
            success(data) {
                updateStoresResults(data);
            }
        });
    });
}

module.exports = {
    init() {
        if (!$('.results').data('has-results')) {
            $('.store-locator-no-results').show();
        }
    },

    detectLocation() {
        // clicking on detect location.
        $('.detect-location').on('click', () => {
            $.spinner().start();
            if (!navigator.geolocation) {
                $.spinner().stop();
                return;
            }

            navigator.geolocation.getCurrentPosition((position) => {
                const $detectLocationButton = $('.detect-location');
                let url = $detectLocationButton.data('action');
                const radius = $('.results').data('radius');
                const urlParams = {
                    radius,
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };

                url = appendToUrl(url, urlParams);
                $.ajax({
                    url,
                    type: 'get',
                    dataType: 'json',
                    success(data) {
                        $.spinner().stop();
                        updateStoresResults(data);
                        $('.select-store').prop('disabled', true);
                    }
                });
            });
        });
    },

    search() {
        $('.store-locator-container form.store-locator').submit(function (e) {
            e.preventDefault();
            search($(this));
        });
        $('.store-locator-container .btn-storelocator-search[type="button"]').click(function (e) {
            e.preventDefault();
            search($(this));
        });
        $('.store-locator-container .js-btn-find-by-location').click((e) => {
            e.preventDefault();
            findStoreByLocation();
        });
    },

    selectStore() {
        $('.store-locator-container').on('click', '.js-select-store', ((e) => {
            e.preventDefault();
            let isDoorDash = $(e.target).parents('#inStoreInventoryModal').hasClass('js-door-dash-store');
            var selectedStore = e.target.closest('.js-card-body');
            var storeData = $(selectedStore).data('store-info');
            var data = {
                storeID: storeData.ID,
                name: storeData.name,
                searchRadius: $('#radius').val(),
                searchPostalCode: $('#store-postal-code').val(),
                storeDetailsHtml: $(selectedStore).find('.store-details').html(),
                isDoorDash: isDoorDash,
                event: e
            };
            $('body').trigger('store:selected', data);
        }));
    },
    displayHours() {
        hoursTooltip.initHoursPopup();
    },
    updateSelectStoreButton() {
        $('body').on('change', '.select-store-input', (() => {
            $('.select-store').prop('disabled', false);
        }));
    },
    expandStoresEvent(modalId, $scope = null) {
        initExpandStoresEvent(modalId, $scope);
    }
};

