const validation = require('./clientSideValidation').functions;
/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {object} button - button that was clicked for email sign-up
 * @param {boolean} isRedirect - enable to redirect on address, that was got from request
 * @param {object} form - cuurent form
 */
function displayMessage(data, button, isRedirect, form) {
    const $form = form;
    $form.spinner().stop();
    const $messageContainer = $form.find('.js-email-signup-message');
    $messageContainer.removeClass('m-success m-error');
    $messageContainer.addClass(data.success ? 'm-success' : 'm-error');

    if (data.msg) {
        $messageContainer.append(data.msg);
    }

    if (data.success && isRedirect) {
        window.location.href = data.subscriptionFormUrl;
    }
    setTimeout(() => {
        $messageContainer.html('');
        button.removeAttr('disabled');
    }, 3000);
}

module.exports = () => {
    $('#footer-signup-form').on('submit', (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();

        const button = $('.js-subscribe-email');
        const url = button.data('href');
        const emailId = $('input[name=hpEmailSignUp]').val();
        const $form = $('#footer-signup-form');

        if (!validation.validateForm($form)) {
            return;
        }

        validation.clearForm($form);
        $form.spinner().start();
        $(button).attr('disabled', true);

        $.ajax({
            url,
            type: 'post',
            dataType: 'json',
            data: {
                emailId
            },
            success(data) {
                displayMessage(data, button, true, $form);
            },
            error(err) {
                displayMessage(err, button, false, $form);
            }
        });
    });
};
