'use strict';

const formValidation = require('../components/formValidation');
const waiNotify = require('../components/waiNotify');
const googleCaptcha = require('../googleCaptcha');
const isCaptchaEnable = googleCaptcha.isCaptchaEnable();

let items = $('.js-wishlist-items').data('items');
if (items) {
    let arr = items.split(',');
    let icons = $('body').find('.js-wishlistTile').toArray();

    arr.forEach((item) => {
        icons.forEach((icon) => {
            if (item === $(icon).data('pid')) {
                $(icon).addClass('b-product_wishlist-registered');
            }
        });
    });
}

function showModal(pid) {
    let $modalContainer = $($('.js-login-modal').html());

    $('body').append($modalContainer);
    if (pid) {
        $('.modal-dialog').find('.js-login-modal-submit').attr('data-pid', pid);
    }
    window.dialogManager.openDialog('modal', '#js-login-modal');

    $('.modal-dialog').find('.alert-danger').remove();

    if (isCaptchaEnable) {
        googleCaptcha.modalInit.call(module.exports);
    }
}

function addToWishlistAfterLogIn(pid) {
    var url = $('.js-login-modal-submit').data('wishlist');
    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: {
            pid: pid
        }
    });
}
module.exports = {
    addToWishlist: function () {
        $('body').on('click', '.js-wishlistTile', function (e) {
            e.preventDefault();
            var $this = $(this);
            var url = $this.attr('href');
            var pid = $this.attr('data-pid');
            var addToWishlistMessage = $this.data('add-message');
            var removeFromWishlistMessage = $this.data('remove-message');
            let isPdpIcon = $('.js-pdp-container').length > 0 && $this.parents('.js-product_tile-inner').length === 0;
            let wishlistIcon = isPdpIcon ? $('.js-wishList-pdp') : $this;
            if (!url || !pid) {
                return;
            }
            $.spinner().start();
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: {
                    pid: pid
                },
                success: function (data) {
                    if (data.success) {
                        const $wishListItemsContainer = $('.js-wishlist-items');
                        let wishListItems = $wishListItemsContainer.attr('data-items');
                        if (data.isGuestUser) {
                            showModal(pid);
                            $.spinner().stop();
                            return;
                        } else if (data.itemAdded) {
                            wishlistIcon.addClass('b-product_wishlist-registered');
                            let updatedList = wishListItems + ',' + pid;
                            $wishListItemsContainer.attr('data-items', updatedList);
                            waiNotify(addToWishlistMessage);
                        } else {
                            wishlistIcon.removeClass('b-product_wishlist-registered');
                            wishListItems.replace(',' + pid, '').replace(pid, '');
                            $wishListItemsContainer.attr('data-items', wishListItems);
                            waiNotify(removeFromWishlistMessage);
                        }
                        $.spinner().stop();
                    }
                },
                error: function () {
                }
            });
        });
    },
    LogIn: function () {
        const that = this;

        $('body').on('submit', '.login-form', function (e) {
            var button = $('.js-login-modal-submit');
            var pid = $(button[button.length - 1]).data('pid');
            e.preventDefault();
            e.stopPropagation();

            var $form = $(this);
            var url = $form.data('url');
            var data = $form.serialize();
            var modalRecaptcha = $form.find('#js-g-recaptcha');
            var recaptchaRef = modalRecaptcha.data('recaptcha-ref');
            const redirectURl = window.location.href;

            const isSuccessCaptcha = googleCaptcha.checkReCaptchaStatus.call(that);
            if (!isSuccessCaptcha && isCaptchaEnable) {
                return false;
            }

            if (isCaptchaEnable) {
                googleCaptcha.cleanErrorMessage();
            }

            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: data,
                success(data) {
                    if (!data.success) {
                        if (isCaptchaEnable) {
                            window.grecaptcha.reset(recaptchaRef);
                        }

                        formValidation($form, data);
                        $form.trigger('login:error', data);
                        $('.js-login-form-password').val('');
                    } else {
                        $form.trigger('login:success', data);
                        if (pid) {
                            addToWishlistAfterLogIn(pid);
                        }
                        window.location.href = redirectURl;
                    }
                },
                error() {
                    if (isCaptchaEnable) {
                        window.grecaptcha.reset(recaptchaRef);
                    }
                }
            });
        });

        $('body').on('click', '.js-login-modal-submit', function (e) {
            e.preventDefault();
            e.stopPropagation();
            var $form = $(this);
            $form.submit();
        });

        $('body').on('keydown', '.login-form input', function (e) {
            if (e.keyCode === 13) {
                e.preventDefault();
                e.stopPropagation();
                $(this).closest('form').submit();
            }
        });
    },
    createAccount: function () {
        $('body').on('click', '.js-modal-create-account', () => {
            var button = $('.js-login-modal-submit');
            var pid = $(button[button.length - 1]).data('pid') ? '&pid=' + $(button[button.length - 1]).data('pid') : '';
            var urlParsed = '&urlParsed=true';
            window.location.href = $('.js-modal-create-account').data('url') + '?rurl=' + encodeURIComponent(location.pathname) + pid + urlParsed;
        });
    },
    resetPassword: function () {
        $('body').on('submit', '.reset-password-form', function (e) {
            e.preventDefault();

            if ($(e.target).find('.js-reset-password').hasClass('m-green')) {
                return window.dialogManager.closeDialog('requestPasswordResetModal');
            }

            const $form = $(this);
            const url = $form.data('url');
            $form.spinner().start();

            $.ajax({
                url,
                type: 'post',
                dataType: 'json',
                data: $form.serialize(),
                success(data) {
                    $form.spinner().stop();
                    if (!data.success) {
                        formValidation($form, data);
                    } else {
                        const $title = $('#requestPasswordResetModal').find('.request-password-title'),
                            $body = $('.request-password-body'),
                            $btn = $('#submitEmailButton'),
                            titleText = $title.text(),
                            bodyHtml = $body.html(),
                            btnText = $btn.text();

                        $title.text(data.receivedMsgHeading);
                        $body
                            .empty()
                            .append(
                                `<p class="b-form_reset_password-message">${data.receivedMsgBody}</p>`
                            );
                        $btn.addClass('m-green');
                        if (!data.mobile) {
                            $btn.text(data.buttonText).attr(
                                'data-dismiss',
                                'modal'
                            );
                        } else {
                            $('.send-email-btn')
                                .empty()
                                .html(
                                    `<a href="${data.returnUrl}" class="btn btn-primary btn-block">${data.buttonText}</a>`
                                );
                        }

                        $('#requestPasswordResetModal')[0].addEventListener(
                            'dialog:closed',
                            () => {
                                $title.text(titleText);
                                $body.html(bodyHtml);

                                if (!data.mobile) {
                                    $btn.text(btnText).removeAttr(
                                        'data-dismiss'
                                    );
                                }
                            }
                        );
                    }
                },
                error(err) {
                    if (err.responseJSON.redirectUrl) {
                        window.window.location.href = err.responseJSON.redirectUrl;
                    }
                    $form.spinner().stop();
                }
            });
            return false;
        });
    },
    openWishList: function () {
        $('body').on('click', '.js-wishlist-link', () => {
            let url = $('.b-header_button-wishlist.js-wishlist-link').data('url');
            $.ajax({
                url,
                type: 'get',
                data: {
                    login: true
                },
                success(data) {
                    if (data.isGuestUser) {
                        showModal();
                        $.spinner().stop();
                    } else {
                        location.href = url;
                    }
                },
                error() {
                }
            });
        });
    },
    clearFieldAfterClosing: function () {
        $('body').on('dialog:closed', '#requestPasswordResetModal', () => {
            $('#reset-password-email').val('');
        });
    }
};
