const dataLayer = require('../dataLayer/dataLayer');
const { appendToUrl } = require('../utils/urlUtil');
const waiNotify = require('../components/waiNotify');

const removeButtonClass = '.js-remove-product';
const removeConfirmationButtonClass = '.js-product-delete-confirmation-btn';

/**
 * replace content of modal
 * @param {string} actionUrl - url to be used to remove product
 * @param {string} productID - pid
 * @param {string} productName - product name
 * @param {string} uuid - uuid
 */
function prepareConfirmationModalContent(actionUrl, productID, productName, uuid) {
    const $deleteConfirmBtn = $(removeConfirmationButtonClass);
    const $productToRemoveSpan = $('.js-product-to-remove');

    $deleteConfirmBtn.data('pid', productID);
    $deleteConfirmBtn.data('action', actionUrl);
    $deleteConfirmBtn.data('uuid', uuid);

    $productToRemoveSpan.empty().append(productName);
}

/**
 * get current target data
 * @param {event} e - event of button click
 * @returns {object} - current data
 */
function getRemoveConfirmationData(e) {
    const button = $(e.target);
    return {
        productID: button.data('pid'),
        uuid: button.data('uuid'),
        url: button.data('action')
    };
}

module.exports = function () {
    const $body = $('body');

    $body.on('click', removeButtonClass, (e) => {
        e.preventDefault();

        const button = $(e.target);
        const actionUrl = button.data('action');
        const productID = button.data('pid');
        const productName = button.data('name');
        const uuid = button.data('uuid');

        prepareConfirmationModalContent(actionUrl, productID, productName, uuid);
    });

    $body.on('click', removeConfirmationButtonClass, (e) => {
        e.preventDefault();

        const $quantityError = $('.js-stock-error');
        $quantityError
            .addClass('h-hidden')
            .empty();

        const button = $(e.target);

        const currentData = getRemoveConfirmationData(e);
        const productID = currentData.productID;
        const uuid = currentData.uuid;
        let url = currentData.url;

        const urlParams = {
            pid: productID,
            uuid
        };
        url = appendToUrl(url, urlParams);

        $.spinner().start();

        $.ajax({
            url,
            type: 'get',
            dataType: 'json',
            success(data) {
                dataLayer.removeFromCartDataLayerEventInit(productID);
                if (data.basket.items.length === 0) {
                    $body.trigger('basket:empty');
                } else {
                    if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                        for (let i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                            $(`.uuid-${data.toBeDeletedUUIDs[i]}`).remove();
                        }
                    }

                    $(`.uuid-${uuid}`).remove();

                    if (!data.basket.hasBonusProduct) {
                        $('.bonus-product').remove();
                    }

                    if (data.isFromCheckout) {
                        $('.js-remove-product[data-uuid="' + uuid + '"]').closest('.b-shipping_product').remove();
                        $('.b-product_line_item.product-line-item[data-product-line-item="' + uuid + '"]').closest('.b-checkout_aside-line_item').remove();
                        $('.js-number-of-items').empty().append(data.order.resources.numberOfItems);

                        $body
                            // .trigger('basket:update:bonus:products', data)
                            // .trigger('basket:update:totals', data)
                            // .trigger('basket:update:promos', data.totals.discountsHtml)
                            .trigger('checkout:updateCheckoutView', data)
                            .trigger('cart:update');

                        if (data.wasMixedShipping) {
                            window.location.reload();//refresh page after removing item
                        }

                    } else {
                        $body
                            .trigger('minicart:update:qty', { qty: data.basket.numItems, resources: data.basket.resources })
                            .trigger('minicart:update:content', data.basket)
                            .trigger('basket:update:totals', data.basket)
                            .trigger('basket:update:discounts', { discounts: data.basket.approachingDiscounts })
                            .trigger('basket:update:promos', data.basket.totals.discountsHtml)
                            .trigger('basket:validate', data.basket)
                            .trigger('setShippingMethodSelection', data.basket);

                    }
                }
                $.spinner().stop();
                waiNotify(button.data('action-message'));
                if (data.basket.items.length === 1 && !data.isMixShipmentEnabled) {
                    $('.js-shipping-form-group .js-pickup-option').prop('disabled', false);
                }
            },
            error(err) {
                if (err.responseJSON.redirectUrl) {
                    window.window.location.href = err.responseJSON.redirectUrl;
                } else {
                    // Applies to Checkout - Shipping - Remove product when only one product left
                    if ($('.shipping-section .b-shipping_product').length === 2 || $('.shipping-section .in-storepickup-card').length === 1) {
                        window.location.reload();
                    }
                    $body.trigger('basket:notify', err.responseJSON.errorMessage);
                    $.spinner().stop();
                    waiNotify(button.data('action-message-canceled'));
                }
            }
        });
    });
};
