module.exports = function () {
    const hamburgerButton = document.querySelector('[data-js-toggle-hamburger]');
    const desktopHamburgerButton = document.querySelector('[data-js-toggle-hamburger-desktop]');

    if (hamburgerButton) {
        hamburgerButton.addEventListener('click', (event) =>
            window.dialogManager.openDialog('panel', '#hamburger-menu', event.target));
    }

    if (desktopHamburgerButton) {
        desktopHamburgerButton.addEventListener('click', (event) => {
            const hamburgerMenu = document.querySelector('.n-hamburger');
            const hamburgerContainer = document.querySelector('.hamburger-container');
            const nMainMenu = document.querySelector('.n-main_menu');
            const nPanel = document.querySelector('#hamburger-menu');
            const windowHeight = window.innerHeight;
            const desktopDomainSwitcher = document.querySelector('.js-utility_domain_switcher');
            const domainHeight = desktopDomainSwitcher.offsetHeight;
            const body = document.body;

            nMainMenu.style.height = `${windowHeight - 300}px`;
            nPanel.style.height = `${windowHeight - 300}px`;
            body.classList.add('has-dialog');
            hamburgerContainer.appendChild(hamburgerMenu); // Move the #hamburger-menu element to the .hamburger-container

            if (window.scrollY === 0) {
                if (domainHeight > 0) {
                    hamburgerMenu.classList.add('scrolled-to-top');
                }
            } else {
                hamburgerMenu.classList.remove('scrolled-to-top');
            }

            hamburgerContainer.style.display = 'block'; // Display the .hamburger-container

            window.addEventListener('resize', () => {
                const newWindowHeight = window.innerHeight;
                nMainMenu.style.height = `${newWindowHeight - 300}px`;
                nPanel.style.height = `${newWindowHeight - 300}px`;
            });

            desktopHamburgerButton.classList.add('m-toggled');

            window.dialogManager.openDialog('panel', '#hamburger-menu', event.target);
        }, 400);

        desktopHamburgerButton.addEventListener('transitionend', () => {
            const hamburgerContainer = document.querySelector('.hamburger-container');
            hamburgerContainer.style.display = '';
        });
    }
};
