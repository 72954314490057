module.exports = () => {
    const searchForm = document.getElementById('simple-search-form');

    if (!searchForm) {
        return;
    }

    const $MobileSearchInput = $('#header-search-area');
    const $headerWrapper = $('.l-header_wrapper');
    const $headerBanner = $('.header-banner.js-show');
    const $headerSearch = $('.l-header-search');
    const $plpHeaderFilters = $('.js-plp_header-filters');
    const $productGrid = $('.l-plp_grid');

    const headerWrapperHeight = $headerWrapper.innerHeight();
    const headerSearchHeight = $headerSearch.innerHeight();
    const headerBannerHeight = $headerBanner.innerHeight();

    /**
     * Positions the sticky filter based on various conditions
     */
    function stickyFilterPositioning() {
        const isMobileSearchActive = $MobileSearchInput.hasClass('m-active');
        const isHeaderSticky = $headerWrapper.data('headerSticky');
        const productGridTop = $productGrid.length ? $productGrid.offset().top : null;
        const stickyHeaderHeight = $headerWrapper.hasClass('l-header-sticky') ? headerWrapperHeight : 0;

        let topPosition = stickyHeaderHeight;

        if (isMobileSearchActive) {
            topPosition += headerSearchHeight;
        }

        if (headerBannerHeight !== undefined && !isHeaderSticky) {
            topPosition -= headerBannerHeight;
        }

        if (productGridTop !== null && $(window).scrollTop() >= productGridTop - stickyHeaderHeight) {
            $plpHeaderFilters.addClass('filter-sticky').css('top', topPosition);
        } else {
            $plpHeaderFilters.removeClass('filter-sticky');
        }
    }

    $(document).scroll(() => {
        const scroll = $(document).scrollTop();

        $headerWrapper.toggleClass('l-header-sticky', scroll >= 1);

        if (!$headerWrapper.data('headerSticky')) {
            $headerWrapper.toggleClass('js-header-sticky', scroll >= 1);
        }

        if (!$MobileSearchInput.hasClass('js-clicked-search')) {
            $MobileSearchInput.toggleClass('m-active', scroll < 1);
        }

        stickyFilterPositioning();
    });

    const SearchCombo = require('./forms/SearchCombo');
    const searchComboHeader = new SearchCombo(searchForm);
    searchComboHeader.init();

    const $MobileSearchIcon = $('.js-search-icon-mobile');

    $MobileSearchIcon.on('click', (e) => {
        e.preventDefault();
        $MobileSearchInput.toggleClass('m-active js-clicked-search');
        stickyFilterPositioning();
    });
};
