/**
 * @param {string} userId - userId that was signed in
 */
function loginDataLayerEventInit(userId) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'login',
        userId
    });
}

/**
 * push Product In DataLayer object, that was added to cart
 * @param {int} productQuantity - quantity of product
 * @param {object} productData - product data info
 * @param {string} currencyCode - currency code for current session
 */
function addToCartDataLayerEventInit(productQuantity, productData, currencyCode) {
    const jsProductDetailSelector = document.querySelector('.js-product-detail-data');

    if (!productData) {
        const productDataJson = jsProductDetailSelector.getAttribute('data-product');
        productData = JSON.parse(productDataJson);
    }
    if (!currencyCode) {
        currencyCode = jsProductDetailSelector.getAttribute('data-currency');
    }

    let isExsistEvent = false;
    let eventIndex = null;

    productData.quantity = productQuantity;

    window.dataLayer.forEach((element, index) => {
        if (element.event === 'addToCart') {
            isExsistEvent = true;
            eventIndex = index;
        }
    });

    if (isExsistEvent) {
        window.dataLayer[eventIndex].ecommerce.add.products.push(productData);
    } else {
        window.dataLayer.push({
            event: 'addToCart',
            ecommerce: {
                currencyCode,
                add: {
                    products: [productData]
                }
            }
        });
    }
}

/**
 * push Product In DataLayer object, that is during checkout process
 */
function onCheckout() {
    const productInCheckoutSelector = document.querySelector('.js-product-in-checkout');
    const productsInBasketJson = productInCheckoutSelector.getAttribute('data-products');
    const productsInBasket = JSON.parse(productsInBasketJson);
    const userStatus = productInCheckoutSelector.getAttribute('data-user');
    const orderInformation = productInCheckoutSelector.getAttribute('data-order-information');
    const orderInformationObject = JSON.parse(orderInformation);

    if (userStatus === 'unregistered') {
        window.dataLayer.push({
            event: 'CheckoutAsGuestUser',
            userId: 'Guest_User'
        });
    }
    window.dataLayer.push({
        ecommerce: {
            purchase: {
                actionField: {
                    id: orderInformationObject.id,
                    affiliation: orderInformationObject.affiliation,
                    revenue: orderInformationObject.revenue,
                    tax: orderInformationObject.tax,
                    shipping: orderInformationObject.shipping,
                    coupon: orderInformationObject.coupon || ''
                }
            },
            products: productsInBasket
        }
    });
}

/**
 * push Product In DataLayer object
 *
 * @param {Object} product
 */
function pushProductInDataLayer(product) {
    window.dataLayer.push({
        event: 'productClick',
        ecommerce: {
            click: {
                products: [{
                    name: product.name,
                    id: product.id,
                    price: product.price,
                    category: product.category
                }]
            }
        },
        eventCallback: () => { }
    });
}

/**
 * push event to dataLayer for click on add to baby registry button
 */
function babyRegistryEvent() {
    $(document).on('baby-registry.clicked', () => {
        const productDataSelector = document.querySelector('.js-product-detail-data');
        const productDataJson = productDataSelector.getAttribute('data-product');
        const product = JSON.parse(productDataJson);

        window.dataLayer.push({
            event: 'addToRegistry',
            Products: {
                name: product.name,
                id: product.id,
                price: product.price
            }
        });
    });
}

/**
 * push Product In DataLayer object
 */
function pushProductDetail() {
    const productDataSelector = document.querySelector('.js-product-detail-data');
    const productDataJson = productDataSelector.getAttribute('data-product');
    const product = JSON.parse(productDataJson);

    window.dataLayer.push({
        ecommerce: {
            detail: {
                products: [{
                    name: product.name,
                    id: product.id,
                    price: product.price,
                    category: product.category,
                    brand: product.brand
                }]
            }
        }
    });
}

/**
 * push Products In DataLayer object
 */
function pushProductsInDataLayer() {
    const productsJsonSelector = document.querySelectorAll('.js-plp');

    if (!productsJsonSelector.length) {
        return;
    }
    const productsJson = productsJsonSelector[productsJsonSelector.length - 1].getAttribute('data-products');
    const productsArray = JSON.parse(productsJson);
    const currencyCode = productsJsonSelector[productsJsonSelector.length - 1].getAttribute('data-currency-code');

    let isExistImpressions = false;
    let ecommerceIndex = null;

    window.dataLayer.forEach((dataLayerObject, index) => {
        if (dataLayerObject.ecommerce && dataLayerObject.ecommerce.impressions) {
            isExistImpressions = true;
            ecommerceIndex = index;
        }
    });

    if (isExistImpressions) {
        const previousProducts = window.dataLayer[ecommerceIndex].ecommerce.impressions;
        const allProduct = previousProducts.concat(productsArray);
        const lastPosition = previousProducts.length;

        productsArray.forEach((product, index) => {
            product.position = lastPosition + index + 1;
        });

        window.dataLayer[ecommerceIndex].ecommerce.impressions = allProduct;
    } else {
        productsArray.forEach((product, index) => {
            product.position = index + 1;
        });
        window.dataLayer.push({
            ecommerce: {
                currencyCode,
                impressions: productsArray
            }
        });
    }
}

/**
 *  remove Product In DataLayer object, that was added to cart
 *  @param {int} productId product id for deleting
 *  @returns {undefined}
 */
function removeFromCartDataLayerEventInit(productId) {
    const productsDataJson = document.querySelector('.js-product-in-basket').getAttribute('data-product');
    const productsData = JSON.parse(productsDataJson);
    let removeFromCartEventPosition = null;
    let isFirstDeleting = false;

    const productForDeleting = productsData.find(productData => productData.id === String(productId) || productData.variationId === String(productId));

    delete productForDeleting.variationId;

    window.dataLayer.forEach((element, index) => {
        if (element.event === 'removeFromCart') {
            isFirstDeleting = true;
            removeFromCartEventPosition = index;
        }
    });

    if (isFirstDeleting) {
        window.dataLayer[removeFromCartEventPosition].ecommerce.remove.products.push(productForDeleting);
    } else {
        window.dataLayer.push({
            event: 'removeFromCart',
            ecommerce: {
                remove: {
                    products: [productForDeleting]
                }
            }
        });
    }
}

function storeLocatorEvent(locateType) {
    window.dataLayer.push({
        event: 'locateStore',
        locateType: locateType,
        radius: $('.js-radius').val(),
        locationEntered: $('.js-storelocator-input').val()
    });
}

module.exports = {
    removeFromCartDataLayerEventInit,
    pushProductsInDataLayer,
    pushProductInDataLayer,
    onCheckout,
    addToCartDataLayerEventInit,
    loginDataLayerEventInit,
    storeLocatorEvent,
    babyRegistryEvent,
    pushProductDetail,
    cart() {
        $('.js-cart-content').each((idx, el) => {
            const $el = $(el);

            const orderDataLayer = $el.data('orderDataLayer');

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'checkout',
                ecommerce: {
                    checkout: {
                        actionField: { step: 1 },
                        products: orderDataLayer.products
                    }
                }
            });
        });
    },
    checkoutEvents() {
        $('.js-chechout-login-container').each((idx, el) => {
            const $el = $(el);

            const orderDataLayer = $el.data('orderDataLayer');

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'checkout',
                ecommerce: {
                    checkout: {
                        actionField: { step: 2 },
                        products: orderDataLayer.products
                    }
                }
            });
        });
    },
    reportStep(stepNo, products) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'checkout',
            ecommerce: {
                checkout: {
                    actionField: { step: 3 + stepNo }, // 3 is shipping step
                    products
                }
            }
        });
    }
};
