const stickyHeaderHeight = {
    init($headerWrapper) {
        const $window = $(window);
        const domainSwitcherHeight = $('.js-utility_domain_switcher').height();
        const $headerBannerBtn = $('.header-banner .close-button');
        const headerBannerHeight = $('.header-banner.js-show').height();
        const $content = $('.l-content');
        const headerTop = $headerWrapper.height();

        //check other types of pages
        const searchContainer = $('.js-search-container');

        let headerHeight;

        /**
         * Adjusts the header height based on the window width and presence of search container
         */

        if ($window.width() < window.styleConstants.breakpoint.small) {
            if (searchContainer.length > 0) {
                headerHeight = (headerTop - 30);
            } else {
                headerHeight = (headerTop - 66);
            }
        } else {
            headerHeight = (headerTop);
        }

        $content.css('margin-top', headerHeight);


        $headerBannerBtn.on('click', () => {
            $content.css('margin-top', headerHeight - headerBannerHeight - domainSwitcherHeight);
        });
    }
};

/**
 * Initializes the sticky header height and handles resize event
 * @module stickyHeaderHeight
 * @exports initStickyHeaderHeight
 */
module.exports = function () {
    const $headerWrapper = $('.l-header_wrapper');

    window.addEventListener('resize', () => {
        stickyHeaderHeight.init($headerWrapper);
    });

    if ($headerWrapper.is(':visible')) {
        stickyHeaderHeight.init($headerWrapper);
    } else {
        const intervalHandler = setInterval(() => {
            if ($headerWrapper.is(':visible')) {
                clearInterval(intervalHandler);
                stickyHeaderHeight.init($headerWrapper);
            }
        }, 100);
    }
};
