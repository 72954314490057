/* eslint-disable jsdoc/require-returns */
/* globals google */

/** Google Address Lookup
* Integration with Google Places API
 */
let autocomplete;

const dwAddressForms = [
    {
        id: 'storelocatorForm',
        forms: [{
            postal_code: 'js-zip-input',
            country: 'js-country-select',
            state: 'js-state-select',
            city: 'js-city-input',
            address: 'js-storelocator-input',
            type: 'geocode'
        }]
    },
    {
        id: 'checkout-main',
        forms: [
            {
                postal_code: 'billingZipCode',
                country: 'billingCountry',
                state: 'billingState',
                city: 'billingAddressCity',
                address: 'billingAddressOne',
                type: 'address'
            }
        ]
    },
    {
        id: 'gift-registry-form',
        forms: [{
            postal_code: 'postalCode',
            country: '',
            state: 'stateCode',
            city: 'city_address',
            address: 'address1',
            type: 'address'
        }]
    }
];

/**
 * Gets the correct fields for the current page
 */
function getForms() {
    const pageInfo = dwAddressForms.find(element => $('#' + element.id).length);

    return pageInfo && pageInfo.forms;
}

/**
 * Parses Google Place component and returns the value by type
 *
 * @param {*} place
 * @param {*} type
 * @returns {*} value
 */
function getPlaceComponent(place, type) {
    let value = '';
    for (let i = 0; i < place.address_components.length; i++) {
        const addressType = place.address_components[i].types[0];

        if (addressType === type) {
            value = type !== 'administrative_area_level_1' && type !== 'country'
                ? place.address_components[i].long_name
                : place.address_components[i].short_name;
        }
    }
    return value;
}

/**
 * Sets element value
 *
 * @param {*} elementID
 * @param {*} value
 */
function setElementValue(elementID, value) {
    let val = value;
    if (val === null) {
        val = '';
    }
    const element = document.getElementById(elementID);
    switch (element.type) {
        case 'select-one':
            for (let i = 0; i < element.options.length; i++) {
                if (element.options[i].value === value) {
                    element.options[i].selected = true;
                    return;
                }
            }
            break;
        default:
            element.value = val;
            break;
    }
}


/**
 *  Fills in all required fields using the name of Address field
 */
function fillInAddress() {
    const place = this.getPlace();
    const formInfo = this.formInfo;
    if (typeof formInfo !== 'undefined') {
        // Get the place details from the autocomplete object.
        // eslint-disable-next-line prefer-const
        if (place) {
            Object.keys(formInfo).forEach(component => {
                const elementID = formInfo[component];
                if (elementID) {
                    document.getElementById(elementID).value = '';
                }
            });
            // Get each component of the address from the place details,
            // and then fill-in the corresponding field on the form.
            // Fill in Address control
            const streetNumber = getPlaceComponent(place, 'street_number');
            const address = getPlaceComponent(place, 'route');
            if (streetNumber) {
                setElementValue(formInfo.address, `${streetNumber}, ${address}`);
            } else {
                setElementValue(formInfo.address, address);
            }
            $(`#${formInfo.address}`).trigger('focusout');// re-trigger validation
            // Fill-in ZIP Code control
            setElementValue(formInfo.postal_code, getPlaceComponent(place, 'postal_code'));
            // Fill-in City control
            setElementValue(formInfo.city, getPlaceComponent(place, 'locality'));
            // Fill-in Country control
            if (formInfo.country !== '') {
                setElementValue(formInfo.country, getPlaceComponent(place, 'country'));
            }
            // Fill-in State control
            setElementValue(formInfo.state, getPlaceComponent(place, 'administrative_area_level_1'));
            $(formInfo.state).trigger('change'); // trigger shipping method update logic
        }
    }
}

/*
 * Prevent form submit when user select autocomplition through Return key
 */
function preventFormSubmit(event) {
    if (event.keyCode === 13) {
        event.preventDefault();
        event.stopPropagation();
    }
}

/**
 * Create the autocomplete object, restricting the search predictions to
 * geographical location types.
 */
function init() {
    const forms = getForms();
    if (forms) {
        // Binding autocomplete to Address element
        forms.forEach(form => {
            Array.from(document.getElementsByClassName(form.address)).forEach(inputElement => {
                autocomplete = new google.maps.places.Autocomplete(
                    inputElement, {
                        types: [form.type || 'geocode'],
                        componentRestrictions: {
                            country: 'ca'
                        }
                    }
                );
                // Avoid paying for data that you don't need by restricting the set of
                // place fields that are returned to just the address components.
                autocomplete.setFields(['address_component']);
                // When the user selects an address from the drop-down, populate the
                // address fields in the form.
                const shippingForm = inputElement.closest('.js-shipping-fieldset');

                if (shippingForm) {
                    const state = shippingForm.querySelector('.' + form.state);
                    const postal_code = shippingForm.querySelector('.' + form.postal_code);
                    const city = shippingForm.querySelector('.' + form.city);
                    const country = form.country !== '' ? shippingForm.querySelector('.' + form.country) : '';
                    autocomplete.inputId = inputElement.id;
                    autocomplete.formInfo = {
                        postal_code: postal_code.id,
                        country: country !== '' ? country.id : '',
                        state: state.id,
                        city: city.id,
                        address: inputElement.id
                    };
                    autocomplete.addListener('place_changed', fillInAddress);
                    // Prevent form submit when user select autocomplition through Return key
                    inputElement.addEventListener('keydown', preventFormSubmit);
                }
            });
        });
    }
}

module.exports.init = () => {
    if (document.readyState === 'complete') {
        init();
    } else {
        document.onreadystatechange = function () {
            if (document.readyState === 'complete') {
                init();
            }
        };
    }
};
