/* globals google */
const hoursTooltip = require('../components/hoursPopup');
const googleAddress = require('../components/googleaddress');
const dataLayer = require('../dataLayer/dataLayer');
const media = require('../components/utils/media');
const storeLocator = require('../storeLocator/storeSelector');
const waiNotify = require('../components/waiNotify');
const $emptySearch = $('.js-store-locator-emptysearch');
const $noResults = $('.js-store-locator-no-results');

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    let newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') +
        Object.keys(params).map(key => `${key}=${encodeURIComponent(params[key])}`).join('&');

    return newUrl;
}

/**
 * Update DOM elements with Ajax results
 *
 * @param {object} $results - jQuery DOM element
 * @param {string} selector - DOM element to look up in the $results
 */
function updateDom($results, selector) {
    const $updates = $results.find(selector);
    $(selector).empty().html($updates.html());
}

/**
 * Parse Ajax results and updated select DOM elements
 *
 * @param {string} response - Ajax response HTML code
 */
function parseResults(response) {
    const $results = $(response);
    let selectors = [
        '.js-header-bar',
        '.js-filter-bar',
        '.js-product-sort',
        '.js-page-title',
        '.js-product-grid',
        '.js-plp-banner',
        '.js-breadcrumbs'
    ];

    // Update DOM elements that do not require special handling
    selectors.forEach((selector) => {
        updateDom($results, selector);
    });

    let numberOfClicksOnLoadMore = Number(location.hash.split('#')[1]);
    if (numberOfClicksOnLoadMore > 0) {
        $('.js-show-more-btn').trigger('click', ['back', numberOfClicksOnLoadMore]);
    }
}

function updateIcons() {
    let items = $('.js-wishlist-items').data('items');
    if (items) {
        let arr = items.split(',');
        let icons = $('body').find('.js-wishlistTile').toArray();
        arr.forEach((item) => {
            icons.forEach((icon) => {
                if (item === $(icon).data('pid')) {
                    $(icon).addClass('b-product_wishlist-registered');
                }
            });
        });
    }
}

/**
 * Toggle overlay
 * @param {boolean} isLoading - toggle flag
 */

function toggleBusy(isLoading) {
    if (isLoading) {
        $('.js-product-grid')
            .attr('aria-busy', 'true')
            .spinner().start();
    } else {
        $('.js-product-grid')
            .attr('aria-busy', 'false')
            .spinner().stop();
    }
}

function updateAvailabilityMsg() {
    $('body').trigger('tile:updateAvailabilityMsg');
}

function productsUpdate(storeID) {
    toggleBusy(true);
    var locationURL = location.toString();
    locationURL = locationURL.replace(/mystore=\d*/gi, 'mystore=' + storeID);
    $.ajax({
        url: locationURL,
        data: {
            page: $('.js-grid-footer').data('page-number'),
            selectedUrl: locationURL,
            searchPage: false
        },
        method: 'GET',
        success(response) {
            parseResults(response);
            updateIcons();
            updateAvailabilityMsg();
            toggleBusy(false);
        },
        error() {
            toggleBusy(false);
        }
    });
}

/**
 * Gets address location using Google Maps Geocoder
 *
 * @param {*} address
 * @returns {*} result
 */
function geocodeAddress(address) {
    // Init geocoder
    const geocoder = new google.maps.Geocoder();

    return new Promise((resolve, reject) => {
        geocoder.geocode({ address }, (results, status) => {
            if (status === 'OK') {
                resolve(results[0].geometry.location);
            } else {
                reject(new Error(address));
            }
        });
    });
}

/**
 * Renders the results of the search and updates the map
 * @param {object} data - Response from the server
 */
function updateStoresResults(data) {
    const $resultsDiv = $('.js-modal-results');
    const hasResults = data.stores.length > 0;

    $noResults.toggleClass('h-hidden', hasResults);

    if (data.isEmptyQuery) {
        $noResults.addClass('h-hidden');
        $emptySearch.removeClass('h-hidden');
    }

    $resultsDiv.empty()
        .data('has-results', hasResults)
        .data('radius', data.radius)
        .data('search-key', data.searchKey);

    if (data.storesResultsHtml) {
        $resultsDiv.append(data.storesResultsHtml);

        storeLocator.expandStoresEvent('#storeSelectorModal');
    }
}

/**
 * Appends the results of the search and updates the map
 * @param {object} data - Response from the server
 */
function appendStoresResults(data) {
    const $resultsDiv = $('.js-modal-results');

    if (data.storesResultsHtml) {
        let $storesResults = $(data.storesResultsHtml);

        $storesResults.find('.js-select-store').prop('disabled', false);

        storeLocator.expandStoresEvent('', $storesResults);

        $resultsDiv.append($storesResults);
    }
}

/**
 * Search for stores with new zip code
 *
 * @param {*} element
 * @returns {boolean} false to prevent default event
 */
function getNextPage(element) {
    const $pagingOptions = $(element);

    let url = $pagingOptions.data('actionUrl');

    if (url) {
        const payload = {};
        $.ajax({
            url,
            type: 'GET',
            data: payload,
            dataType: 'json',
            success(data) {
                appendStoresResults(data);
            }
        });
    }
    return false;
}

/**
 * Search for stores with new zip code
 * @param {JQuery} element - the target html element
 * @returns {boolean} false to prevent default event
 */
function search(element) {
    const dialog = element.closest('.js-store-inventory-body');
    const spinner = dialog.length ? dialog.spinner() : $('.js-storelocator-map').spinner();
    spinner.start();
    const $form = element.closest('.store-locator');

    const radius = $('.js-radius').find('option:selected').val();
    let url = $form.attr('action');

    const urlParams = { radius, isMobile: media.getBreakpoint() !== 'large' };
    const query = $form.find('[name="postalCode"]').val();
    if (query) {
        $emptySearch.addClass('h-hidden');
        geocodeAddress(query).then((position) => {
            urlParams.lat = position.lat();
            urlParams.lng = position.lng();

            const payload = {};
            url = appendToUrl(url, urlParams);
            $.ajax({
                url,
                type: $form.attr('method'),
                data: payload,
                dataType: 'json',
                success(data) {
                    spinner.stop();
                    updateStoresResults(data);
                    $('.js-select-store').prop('disabled', false);
                }
            });
        }).catch(() => {
            spinner.stop();
            updateStoresResults({
                stores: [],
                locations: ''
            });
        });
    } else {
        spinner.stop();
        updateStoresResults({
            stores: [],
            locations: '',
            isEmptyQuery: true
        });
    }
    return false;
}

function detectLocationAndRenderMap() {
    if (!navigator.geolocation) {
        return;
    }

    const $storeLocatorMap = $('.js-storelocator-map');

    $storeLocatorMap.spinner().start();
    navigator.geolocation.getCurrentPosition(() => {
        // Hide empty query error
        $emptySearch.addClass('h-hidden');
        const $detectLocationButton = $('.js-detect-location');
        const url = $detectLocationButton.data('action');
        const radius = $('.js-radius').val();
        const urlParams = {
            radius
        };
        urlParams.showStoreMobile = media.getBreakpoint() !== 'large';
        $.ajax({
            url,
            type: 'get',
            dataType: 'json',
            data: urlParams,
            timeout: 6000,
            success(data) {
                $storeLocatorMap.spinner().stop();
                updateStoresResults(data);
                $('.js-select-store').prop('disabled', false);
            }
        }).always(() => {
            $storeLocatorMap.spinner().stop();
        });
    }, () => {
        $storeLocatorMap.spinner().stop();
    });
}

function initConfirmationClosePopup() {
    $(document).on('click.closeConfimation', (e) => {
        const $target = $(e.target);
        const $modal = $target.closest('.js-store-inventory-modal');
        const $modalContent = $target.closest('.js-store-inventory-modal-content');
        if ($modal.length && ($target.closest('.js-modal-close-btn').length || !$modalContent.length)) {
            window.dialogManager.closeAll();
        }
        if ($target.closest('.js-close-confirm-select').length) {
            window.dialogManager.closeAll();
            const $selectBtn = $('.js-store-inventory-modal').find('.js-select-store');
            if ($selectBtn.length && $selectBtn.hasClass('js-store-has-been-selected') && !$('.js-selected-store-with-inventory').length) {
                $selectBtn.trigger('click');
            }
            $(document).off('click.closeConfimation');
        }
        if ($target.closest('.js-cancel-close').length) {
            window.dialogManager.closeDialog();
        }
    });
}

function setMyStoreToProfile(storeID) {
    let url = $('.js-selected-my-home-store').data('action-url');
    if (url) {
        $.ajax({
            url,
            type: 'POST',
            data: {
                ID: storeID
            },
            success(result) {
                waiNotify(result.message);
                var locationURL = location.toString();
                locationURL = locationURL.replace(/mystore=\d*/gi, 'mystore=' + result.newStoreId);
                location.assign(locationURL);
                return true;
            }
        });
    }
    return false;
}

function init() {
    googleAddress.init();
    detectLocationAndRenderMap();
}

module.exports = {
    init() {
        if (document.readyState === 'complete') {
            init();
        } else {
            document.onreadystatechange = function () {
                if (document.readyState === 'complete') {
                    init();
                }
            };
        }
    },
    detectLocation() {
        // clicking on detect location.
        $('.js-detect-location').on('click', (() => {
            dataLayer.storeLocatorEvent('Use my location');
            detectLocationAndRenderMap();
        }));
    },

    search() {
        $('.js-store-locator-container-modal form.store-locator').submit(function (e) {
            e.preventDefault();
            search($(this));
        });
        $('.js-store-locator-container-modal .btn-storelocator-search-header[type="button"]').click(function (e) {
            e.preventDefault();
            dataLayer.storeLocatorEvent('FInd Store');
            search($(this));
        });
    },

    changeRadius() {
        $('.js-store-locator-container-modal .js-radius').change(function () {
            const radius = $(this).children('option:selected').val();
            $('.js-modal-results').data('radius', radius);
            if ($('#store-postal-code').val()) {
                search($('.store-locator-container .btn-storelocator-search-header[type="button"]'));
            }
        });
    },
    selectStore() {
        $('.js-card-body').on('click', '.js-select-store', ((e) => {
            let storeList = $(e.target)
                .parents('.js-modal-results')
                .find('.js-card-body')
                .toArray();
            let selectedStore = $(e.target).closest('.js-card-body');
            storeList.forEach((store) => {
                let myStoreMessage = $(store).children('.b-locator_store-subtitle');
                if (myStoreMessage.length && $(store).attr('id') !== selectedStore.attr('id')) {
                    selectedStore.prepend(myStoreMessage);
                    $(store).children('.b-locator_store-subtitle').remove();
                }
            });
            e.preventDefault();
            const storeID = $(e.target).val();
            var dataStore = $('.js-card-body #' + storeID).data('store-info');
            $('.js-card-body').removeClass('m-selected');
            $('.js-card-body #' + storeID).addClass('m-selected');
            $('.js-selected-my-home-store').attr('data-store-info', JSON.stringify(dataStore)).text(dataStore.name);

            $('.js-select-store').prop('disabled', false);
            $(e.target).prop('disabled', true);
            $('.js-selected-icon').addClass('b-header_store-selected-icon');
            setMyStoreToProfile(storeID);
            var pidReadyToAdd = $('.js-selected-my-home-store').attr('data-pid-add');
            if (pidReadyToAdd) {
                $('.js-selected-my-home-store').removeAttr('data-pid-add');
                $('button.js-ISPU[data-pid="' + pidReadyToAdd + '"]').trigger('click', dataStore);
            }
            window.dialogManager.closeDialog();
            productsUpdate(storeID);
        }));
    },
    displayHours() {
        hoursTooltip.initHoursPopup();
    },
    updateSelectStoreButton() {
        $('body').on('change', '.js-select-store-input', (() => {
            $('.js-select-store').prop('disabled', false);
        }));
    },
    loadMoreStores() {
        $('.js-card-body').on('click', '.js-storelocator-loadmore', ((e) => {
            e.preventDefault();
            getNextPage(e.currentTarget);
            e.currentTarget.remove();
        }));
    },
    openMyStores() {
        $('.js-btn-get-store').on('click', (e, form) => {
            if ($('.js-store-inventory-modal').length !== 0) {
                $('.js-store-inventory-modal, .js-confirm-close-popup').remove();
            }
            const htmlTemplate = $('#store-modal-template').html();
            $('.b-page_inner').append(htmlTemplate);
            window.dialogManager.openDialog('panel', '#storeSelectorModal', $('.js-btn-get-store')[0]);
            if (form) {
                $('.js-selected-my-home-store').attr('data-pid-add', form);
            } else {
                $('.js-selected-my-home-store').removeAttr('data-pid-add');
            }
            initConfirmationClosePopup();
        });
    }
};
