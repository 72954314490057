'use strict';

const formHelpers = require('../checkout/formErrors');

function showModal() {
    $('.modal-dialog').find('.alert-danger').remove();
    window.dialogManager.openDialog('modal', '#js-modal-postal');
}

function showModalError() {
    $('.modal-dialog').find('.alert-danger').remove();
    window.dialogManager.openDialog('modal', '#js-modal-postal-error');
}

module.exports = {
    postalCodeMenu: function() {
        $('body').on('click', '.js-postal-code-trigger', () => {
            if (!$('.js-postal-code-trigger').parents().hasClass('summary-postalcode')) {
                showModal();
            }
        });
    },

    postalCodeErrorMenu: function() {
        $('body').on('dialog:closed', '#js-modal-postal-error', () => {
            window.location.reload();
        });
    },

    postForm: function() {
        $('body').on('click', '.js-locationForm-trigger', (e) => {
            e.preventDefault();
            var $form = $(e.currentTarget).closest('form');
            var data = $form.serialize();

            var targetCart = $('.l-content').children().hasClass('js-cart-content') || $('.l-content').children().hasClass('data-checkout-stage');

            var pid = $('.js-home-delivery').data('pid');

            var url = $form.attr('action') + '?pid=' + pid;

            $.ajax({
                url: url,
                type: 'POST',
                dataType: 'json',
                data: data,
                success(data) {
                    if (data.success) {
                        //open modal if cart is invalid
                        if (data.isInvalidBasket && !targetCart) {
                            showModalError();
                        } else if (data.redirectUrl) {
                            location.href = data.redirectUrl;
                        } else {
                            //reload the page so the postal code can be update
                            window.location.reload();
                        }
                    }
                    // look for field validation errors
                    if (data.error) {
                        if (data.fieldErrors.length) {
                            $('.example-message').removeClass('hidden');

                            data.fieldErrors.forEach((error) => {
                                if (Object.keys(error).length) {
                                    $.each(error, (attr) => {
                                        $form.find('*[name=' + attr + ']')
                                            .addClass('is-invalid');
                                        $form.find('.invalid-feedback')
                                            .html(error[attr]);
                                    });
                                }
                            });
                        }
                    }
                }
            });
        });
    },

    postFormModal: function() {
        $('body').on('click', '.js-locationForm-trigger-modal', (e) => {
            e.preventDefault();
            var $form = $(e.currentTarget).closest('form');
            var data = $form.serialize();

            var targetCart = $('.l-content').children().hasClass('js-cart-content') || $('.l-content').children().hasClass('data-checkout-stage');

            $.ajax({
                url: $form.attr('action'),
                type: 'POST',
                dataType: 'json',
                data: data,
                success(data) {
                    if (data.success) {
                        //open modal if cart is invalid
                        if (data.isInvalidBasket && !targetCart) {
                            showModalError();
                        } else {
                            //reload the page so the postal code can be update
                            window.location.reload();
                        }
                    }
                    // look for field validation errors
                    if (data.error) {
                        if (data.fieldErrors.length) {
                            $('.example-message').removeClass('hidden');

                            data.fieldErrors.forEach((error) => {
                                formHelpers.loadFormErrors('.location-postal-code', error, false);
                            });
                        }
                    }
                }
            });
        });
    }
};
