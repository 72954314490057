import Inputmask from 'inputmask';

module.exports = {
    digitsOnly: function() {
        Inputmask('999-999-9999').mask('.js-input-phone');
        $('.js-input-digits-only').on('input', (event) => {
            event.target.value = event.target.value.replace(/\D/g, '');
        });
    }
};
