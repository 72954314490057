function setPromotionsTracking() {
    var promotionsArray = [];

    $('.js-datalayer-promo').each(function() {
        var data = $(this).attr('data-context');

        if (data) {
            data = JSON.parse(data);
            promotionsArray.push(data);
        }
    });

    if (promotionsArray.length > 0) {
        window.dataLayer.push({
            ecommerce: {
                promoView: {
                    promotions: promotionsArray
                }
            }
        });
    }
}

function setCustomerContext() {
    var $dataLayerContext = $('.js-datalayer-context'),
        dataObject = {};

    $dataLayerContext.each(function() {
        var data = $(this).attr('data-context');

        if (data) {
            data = JSON.parse(data);
            $.extend(true, dataObject, data);
        }
    });

    window.dataLayer.push({
        event: 'virtualPageview',
        loginStatus: dataObject.loginStatus,
        userId: dataObject.customerID,
        pageType: dataObject.pageType || ''
    });
}

function setGlobalVariable() {
    var $context = $('.js-global-variables'),
        data = $context.attr('data-context');

    if (data) {
        data = JSON.parse(data);
        window.user_email = 'customerEmail' in data ? data.customerEmail : '';
        window.user_FirstName = 'firstName' in data ? data.firstName : '';
    }
}

module.exports = function () {
    setCustomerContext();
    setPromotionsTracking();
    setGlobalVariable();

    $(document).on('click', '.js-datalayer-promo', function() {
        var data = $(this).attr('data-context');

        if (data) {
            data = JSON.parse(data);
            window.dataLayer.push({
                event: 'promotionClick',
                ecommerce: {
                    promoClick: {
                        promotions: [data]
                    }
                }
            });
        }
    });
};
