module.exports = class InputClear {
    constructor(button) {
        this.button = button;
        this.input = document.getElementById(button.getAttribute('data-clear-input'));
    }

    init() {
        if (!this.input) {
            return;
        }

        this.addEventListeners();
    }

    addEventListeners() {
        this.clearInput = this.clearInput.bind(this);
        this.toggleClearButton = this.toggleClearButton.bind(this);

        this.input.addEventListener('input', this.toggleClearButton);
        this.button.addEventListener('click', this.clearInput);
    }

    toggleClearButton() {
        if (this.input.value.length > 0) {
            this.showButton();
        } else {
            this.hideButton();
        }
    }

    showButton() {
        this.button.classList.add('m-visible');
        $('#header-search-area').addClass('m-opened');
    }

    hideButton() {
        this.button.classList.remove('m-visible');
        $('#header-search-area').removeClass('m-opened');
    }

    showBarcodeBtn() {
        const wrapper = this.input.closest('#header-search-area');
        const barcodeBtn = wrapper && wrapper.querySelector('#scanner_button');

        if (barcodeBtn) {
            barcodeBtn.classList.remove('js-hide-btn');
        }
    }

    clearInput() {
        this.input.value = '';
        this.button.classList.remove('m-visible');
        $('#header-search-area').removeClass('m-opened');

        this.showBarcodeBtn();
    }

    destroy() {
        this.input.removeEventListener('input', this.showClearButton);
        this.button.removeEventListener('click', this.clearInput);
    }
};
