module.exports = {
    initHoursPopup() {
        $(document.body).on('click', '.js-store-hours', ((e) => {
            e.preventDefault();
            const $target = $(e.target);
            const $hoursPopup = $target.next('.js-store-hours-popup');
            const $allPopups = $('.js-store-hours-popup');

            $allPopups.addClass('h-hidden');

            $hoursPopup.removeClass('h-hidden');
            $target.attr('aria-expanded', 'true');

            $hoursPopup.one('click', '.js-locator_popup_close', () => {
                $target.attr('aria-expanded', 'false');
                $allPopups.addClass('h-hidden');
                return false;
            });
        }));
    }
};
