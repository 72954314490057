// @ts-ignore
const Quagga = require('quagga');
const resources = require('resources');

module.exports = function () {

    // Create the QuaggaJS config object for the live stream
    var liveStreamConfig = {
        inputStream: {
            type: 'LiveStream',
            constraints: {
                width: {min: 800},
                height: {min: 400},
                aspectRatio: {min: 1, max: 100},
                facingMode: 'environment' // or "user" for the front camera
            }
        },
        locator: {
            patchSize: 'medium',
            halfSample: true
        },
        numOfWorkers: (navigator.hardwareConcurrency ? navigator.hardwareConcurrency : 4),
        decoder: {
            readers: [
                {format: 'upc_reader', config: {}},
                {format: 'ean_reader', config: {}}
            ]
        },
        frequency: 5,
        locate: true
    };
    // The fallback to the file API requires a different inputStream option.
    // The rest is the same
    var fileConfig = $.extend(
        {},
        liveStreamConfig,
        {
            inputStream: {
                size: 800
            }
        }
    );
    // Start the live stream scanner when the modal opens
    var $liveStreamScannerSelector = $('#livestream_scanner')[0];
    if ($liveStreamScannerSelector) {
        $liveStreamScannerSelector.addEventListener('dialog:open', () => {
            Quagga.init(
                liveStreamConfig,
                (err) => {
                    if (err) {
                        var SystemError = 'getUserMedia is not defined';
                        var ErrorMessage = err.message;
                        var errorHtml = '<div class="alert alert-danger"><strong><i class="fa fa-exclamation-triangle"></i>';

                        if (ErrorMessage === SystemError) {
                            ErrorMessage = resources.errorMessageBarcode;
                            errorHtml += '</strong> ' + ErrorMessage + '</div>';
                        } else {
                            errorHtml += err.name + '</strong>: ' + ErrorMessage + '</div>';
                        }

                        $('#livestream_scanner .modal-body .error').html(errorHtml);

                        Quagga.stop();
                        return;
                    }
                    Quagga.start();
                }
            );
        });
    }

    // Make sure, QuaggaJS draws frames an lines around possible
    // barcodes on the live stream
    Quagga.onProcessed((result) => {
        var drawingCtx = Quagga.canvas.ctx.overlay,
            drawingCanvas = Quagga.canvas.dom.overlay;

        if (result) {
            if (result.boxes) {
                drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute('width')), parseInt(drawingCanvas.getAttribute('height')));
                result.boxes.filter((box) => {
                    return box !== result.box;
                }).forEach((box) => {
                    Quagga.ImageDebug.drawPath(box, {x: 0, y: 1}, drawingCtx, {color: 'green', lineWidth: 2});
                });
            }

            if (result.box) {
                Quagga.ImageDebug.drawPath(result.box, {x: 0, y: 1}, drawingCtx, {color: '#00F', lineWidth: 2});
            }

            if (result.codeResult && result.codeResult.code) {
                Quagga.ImageDebug.drawPath(result.line, {x: 'x', y: 'y'}, drawingCtx, {color: 'red', lineWidth: 3});
            }
        }
    });

    function processResult(code) {
        var url = $('#scanner_button').data('url');
        $.ajax({
            url: url,
            type: 'get',
            data: {code: code},
            dataType: 'json',
            success: function (data) {
                location.href = data.url;
            },
            error: function () {
            }
        });
    }

    // Once a barcode had been read successfully, stop quagga and
    // close the modal after a second to let the user notice where
    // the barcode had actually been found.
    Quagga.onDetected((result) => {
        if (result.codeResult.code) {
            Quagga.stop();
            // @ts-ignore
            $('#livestream_scanner').hide();
            processResult(result.codeResult.code);
        }
    });

    // Stop quagga in any case, when the modal is closed
    if ($liveStreamScannerSelector) {
        $('#livestream_scanner')[0].addEventListener('dialog:closed', () => {
            if (Quagga) {
                Quagga.stop();
            }
        });
    }

    // Call Quagga.decodeSingle() for every file selected in the
    // file input
    $('#livestream_scanner input:file').on('change', (e) => {
        if (e.target.files && e.target.files.length) {
            Quagga.decodeSingle($.extend({}, fileConfig, {src: URL.createObjectURL(e.target.files[0])}), (result) => {
                Quagga.stop();
                processResult(result.codeResult.code);
            });
        }
    });
};
