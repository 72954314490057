const Expand = require('./togglers/Expand');
const CompositedComponent = require('./utils/CompositedComponent');


/**
* Initializes the breadcrumbs disclosure component
*/
function init() {
    const button = document.querySelector('.js-breadcrumbs-disclosure');

    if (button) {
        const Noop = class Noop {
            constructor() {
            }

            init() {
            }

            destroy() {
            }
        };

        new CompositedComponent(new Expand(button), window.styleConstants.breakpoint.small, new Noop());
    }
}

module.exports = {
    init: init
};
