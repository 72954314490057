let loaderTimer;

/**
 * Show a spinner inside a given element
 * @param {JQuery} $target - Element contains spinner.
 *                            Pass body to block the whole page.
 * @param {number|boolean} delay - Delay for spinner animation
 */
function addSpinner($target, delay = 1400) {
    if ($target.hasClass('h-busy')) {
        return;
    }

    const $spinner = $(`
<svg class="b-spinner m-animated" width="38px" height="38px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" stroke-width="6" stroke-linecap="round">
        <circle stroke-opacity=".2" cx="33" cy="33" r="30"></circle>
        <circle class="b-spinner-spinner_line" cx="33" cy="33" r="30"></circle>
    </g>
</svg>
        `);

    $target.addClass('h-busy');

    loaderTimer = setTimeout(() => {
        $target.append($spinner);
    }, delay);
}

/**
 * Remove existing spinner
 * @param  {JQuery} $element - jQuery element that contain spinner
 */
function removeSpinner($element) {
    const $spinner = $element.find('.b-spinner');

    clearTimeout(loaderTimer);
    $element.removeClass('h-busy');
    $spinner.remove();
}

// element level spinner:
$.fn.spinner = function () {
    const $element = $(this);
    const Fn = function () {
        this.start = function (delay) {
            if ($element.length) {
                addSpinner($element, delay);
            }
        };
        this.stop = function () {
            if ($element.length) {
                removeSpinner($element);
            }
        };
    };
    return new Fn();
};

// page-level spinner:
$.spinner = function () {
    const Fn = function () {
        this.start = function () {
            $('body').append($('<div class="b-busy_overlay"></div>'));
            addSpinner($('.b-busy_overlay'));
        };
        this.stop = function () {
            removeSpinner($('body'));
            $('.b-busy_overlay').remove();
        };
    };
    return new Fn();
};
