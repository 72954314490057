

const scrollAnimate = require('../components/scrollAnimate');

/**
 * Display error messages and highlight form fields with errors.
 * @param {string} parentSelector - the form which contains the fields
 * @param {object} fieldErrors - the fields with errors
 * @param {boolean} animate - enable animation to field error
 */
function loadFormErrors(parentSelector, fieldErrors, animate) { // eslint-disable-line
    const canAnimate = animate === undefined ? true : animate;
    // Display error messages and highlight form fields with errors.
    $.each(fieldErrors, (attr) => {
        $(`*[name=${attr}]`, parentSelector)
            .addClass('is-invalid')
            .siblings('.invalid-feedback')
            .html(fieldErrors[attr])
            .show();
    });

    if (canAnimate) {
        // Animate to top of form that has errors
        scrollAnimate($(parentSelector));
    }
}

/**
 * Clear the form errors.
 * @param {string} parentSelector - the parent form selector.
 */
function clearPreviousErrors(parentSelector) {
    $(parentSelector).find('.form-control.is-invalid').removeClass('is-invalid');
    $('.error-message').hide();
}

module.exports = {
    loadFormErrors,
    clearPreviousErrors
};
