let updateMiniCart = true;
let closeMiniCartTimeout;

function updateMiniCartQuantity(data) {
    const hiddenClass = 'b-minicart_icon-quantity_hidden';
    const minicartQuantity = $('.js-minicart-quantity');
    const minicartButton = $('.js-minicart-button');

    if (data.qty > 0) {
        minicartQuantity
            .removeClass(hiddenClass)
            .text(data.qty);
        if (minicartButton.length) {
            minicartButton.attr('title', minicartButton.attr('title').replace(/\d+/, data.qty));
        }
    } else {
        minicartQuantity
            .addClass(hiddenClass)
            .text('0');
        if (minicartButton.length) {
            minicartButton.attr('title', minicartButton.attr('title').replace(/\d+/, '0'));
        }
    }

    if (data.resources) {
        minicartButton.attr('aria-label', data.resources.minicartCountOfItems);
    }
}

function closeMiniCart(dialogID) {
    if ($(dialogID).hasClass('is-open')) {
        window.dialogManager.closeDialog(dialogID);
    }
    window.clearTimeout(closeMiniCartTimeout);
}

function showRestrictionDialog (data) {
    if (typeof(data.message) === 'object' && data.message.shipToHomeRestricted) {
        let $modalContainer = $($('.js-restriction-message').html());
        $('body').append($modalContainer);
        window.dialogManager.openDialog('modal', '#restriction-message');
    }
}
function openMiniCart($trigger, cb) {
    if (parseInt($('.js-minicart-quantity').text(), 10) < 0) {
        return;
    }

    window.dialogManager.openDialog('panel', '#minicart-panel', $trigger[0], $('.js-minicart-panel-close')[0]);

    if (!updateMiniCart) {
        return;
    }

    const $panelYields = $('.js-minicart-panel-yield');
    $panelYields.spinner().start(true);
    $panelYields.attr('aria-busy', 'true');

    const url = $('.js-minicart').data('action-url');
    $.get(url, (data) => {
        $panelYields.html(data);
        updateMiniCart = false;
        $panelYields.spinner().stop();
        $panelYields.attr('aria-busy', 'false');
        return cb();
    });
}

function emptyMiniCart() {
    const emptyMinicartTemplate = $('#minicart-empty-content').html();
    $('.js-minicart-panel-yield').empty().append(emptyMinicartTemplate);
    updateMiniCartQuantity({ qty: 0 });
}

function updateAppoachingDiscounts(discounts) {
    let html = '';
    if (discounts.length > 0) {
        discounts.forEach((item) => {
            html += `<div class="alert m-cart-message" role="alert">${item.discountMsg}</div>`;
        });
    }
    return html;
}

function updateMiniCartProductGrandTotal({items}) {
    if (items) {
        items.forEach((qwe) => {
            const {UUID, priceTotal} = qwe;
            if (UUID) {
                const $grandTotalPrice = $(`.js-grand-total-price-${UUID}`);
                $grandTotalPrice.text(priceTotal.price);
            }
        });
    }
}

function updateOrderDiscount(data) {
    const $orderDiscountWrapper = $('.js-minicart-order-discount');
    const $orderDiscount = $('.js-minicart-order-discount-value');
    const $shippingDiscountWrapper = $('.js-minicart-shipping-discount');
    const $shippingDiscount = $('.js-minicart-shipping-discount-value');

    $orderDiscount.text(data.totals.orderLevelDiscountTotal.formatted);
    $orderDiscountWrapper.toggleClass(window.styleConstants.hideClass, !data.totals.orderLevelDiscountTotal.value);
    $shippingDiscount.text(data.totals.shippingLevelDiscountTotal.formatted);
    $shippingDiscountWrapper.toggleClass(window.styleConstants.hideClass, !data.totals.shippingLevelDiscountTotal.value);
}

function updateMiniCartTotals(data) {
    const $itemsCount = $('.js-minicart-items-count');
    const $total = $('.js-minicart-total');
    const $taxtotal = $('.js-minicart-taxtotal');
    const $shipping = $('.js-minicart-shipping');
    const $subtotal = $('.js-minicart-subtotal');
    const $approachingDiscounts = $('.js-approaching-discounts');
    const $miniCartErrorMessage = $('.js-minicart-error');
    if (!data.valid.error) {
        $miniCartErrorMessage.addClass('h-hidden');
    }
    $itemsCount.text(data.resources.numberOfItems);
    $total.text(data.totals.grandTotal);
    $taxtotal.text(data.totals.totalTax);
    $shipping.text(data.totals.totalShippingCost);
    $subtotal.text(data.totals.subTotal);
    $approachingDiscounts.html(updateAppoachingDiscounts(data.approachingDiscounts));
    updateMiniCartProductGrandTotal(data);
    updateOrderDiscount(data);
}

function openModalDialog(quantity) {
    const $body = $('body');

    if (!$body.find('.js-item-added-to-cart-modal').length) {
        const modalDialogContent = $('#item-added-to-cart').html();
        $body.append(modalDialogContent);
    }

    $body.find('.js-item-added-quantity').text(quantity);
    window.dialogManager.openDialog('modal', '#itemAddedToCartModal', $('.js-add-to-cart')[0]);
}

module.exports = function () {
    const $body = $('body');

    $('.js-minicart-button').on('click', (e) => {
        if (parseInt($('.js-minicart-quantity').text(), 10) !== 0) {
            e.preventDefault();
            openMiniCart($(e.target), () => {});
        }
    });

    $('.js-age-modal-close').click(() => {
        closeMiniCart();
    });

    $body.on('minicart:update:content', (event, data) => updateMiniCartTotals(data));

    $body.on('minicart:update:qty', (event, data) => updateMiniCartQuantity(data));

    $body.on('product:afterAddToCart', (event, data) => {
        var $minicartQuantityEl = $('.js-minicart-quantity');
        var initialQuantity = parseInt($minicartQuantityEl.text()) || 0;
        var resultingQuantity;

        updateMiniCartQuantity({ qty: data.cart.numItems, resources: data.cart.resources });
        updateMiniCart = true;
        resultingQuantity = parseInt($minicartQuantityEl.text()) - initialQuantity;

        if (window.innerWidth >= window.styleConstants.breakpoint.medium) {
            openMiniCart($('.js-add-to-cart'), () => closeMiniCartTimeout = window.setTimeout(() => {
                closeMiniCart('#minicart-panel');
            }, 5000));
            showRestrictionDialog(data);
        } else {
            openModalDialog(resultingQuantity);
            showRestrictionDialog(data);
        }
    });

    $body.on('basket:empty', emptyMiniCart);

    $body.on('change', '.js-minicart .quantity', function () {
        if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            window.location.reload();
        }
    });
};
