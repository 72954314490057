const Combobox = require('./Combobox');
const { appendToUrl } = require('../../utils/urlUtil');

module.exports = class SearchCombo extends Combobox {
    /**
     * SearchCombo
     * @param {domNode} form - form with combobox
     * @description This is simple search implementation. It also could be used as inplace search.
     * To do that, just initialize it without header specific overlay:
     * ```
     *     const searchComboInplace = new SearchCombo(document.querySelector('[data-js-search-combobox-inplace]'));
     *     searchComboInplace.toggleOverlay = function () {};
     *     searchComboInplace.toggleGlobalOverlay = function () {};
     *     searchComboInplace.init();
     * ```
     */
    constructor(form) {
        super(form.querySelector('[data-js-search-combobox]'));
        this.form = form;
        this.suggestionsOvelay = document.querySelector('[data-js-suggestions-overlay]');
        this.clearButton = this.combobox.querySelector('button[type=reset]');
        this.endpoint = this.listbox.getAttribute('data-url');
        this.setupXHR();
        this.formListeners();
        this.initClearButton();
    }

    formListeners() {
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFormMouseenter = this.handleFormMouseenter.bind(this);
        this.handleFormMouseleave = this.handleFormMouseleave.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.form.addEventListener('submit', this.handleSubmit);
        this.clearButton.addEventListener('click', this.handleClose);
        this.form.addEventListener('mouseenter', this.handleFormMouseenter);
        this.form.addEventListener('mouseleave', this.handleFormMouseleave);
    }

    setupXHR() {
        const onError = () => this.toggleSpinner(false);

        this.request = new XMLHttpRequest();
        this.request.addEventListener('error', onError);
        this.request.addEventListener('abort', onError);
        this.request.addEventListener('timeout', onError);
    }

    processResponse(query, response) {
        try {
            // If all falls SF respond with JSON instead of HTML string
            JSON.parse(response);
            this.toggleSpinner(false);
            this.closeListbox();
        } catch (error) {
            if (this.input.value.trim() !== query) {
                // if value changed during request not show outdated suggestions
                return;
            }

            if (document.activeElement !== this.input) {
                this.toggleSpinner(false);
                this.closeListbox();
                return;
            }

            response = response.replace(/<head.*?>(.|\n)*?<\/head>/, '');
            this.listbox.innerHTML = response;

            this.activeIndex = -1;
            this.input.setAttribute('aria-activedescendant', '');

            const totalSuggests = this.listbox
                .querySelector('#search-result-count')
                .getAttribute('data-total');

            this.toggleSpinner(false);
            this.afterSuggestionsUpdate(query, parseInt(totalSuggests, 10));
        }
    }

    getSuggestions(query) {
        let url = appendToUrl(this.endpoint, {q: query.trim()});

        this.request.abort();
        this.request.open('GET', url);
        this.request.onreadystatechange = (response) => {
            if (response.target.readyState === 4) {
                if (response.target.status === 200) {
                    this.processResponse(query.trim(), response.target.responseText);
                } else {
                    this.toggleSpinner(false);
                    this.closeListbox();
                }
            }
        };
        this.request.send();

        this.toggleSpinner(true);
    }

    handleSubmit(event) {
        if (this.goingByLink || !this.input.value.trim()) {
            event.preventDefault();
        }

        if (!this.input.value.trim()) {
            $('body').css('overflow', 'hidden');
            this.input.focus();
        } else {
            this.request.abort();
            this.closeListbox();
            $('body').css('overflow', '');
        }
    }

    selectItem(item) {
        // We do not need default combobox behaviour.
        // Instead of pasting suggestion value we go by the link
        if (item) {
            this.input.value = item.getAttribute('data-suggestion-value');
            this.goingByLink = true;

            let clickEvent;
            if (typeof MouseEvent === 'function') {
                clickEvent = new MouseEvent('click', { view: window, bubbles: true, cancelable: true });
            } else {
                clickEvent = document.createEvent('MouseEvents');
                clickEvent.initEvent('click', true, true);
            }
            item.dispatchEvent(clickEvent);
        }
    }

    handleClick() {
        // We do not need handle click since we have links inside
    }

    handleFocus() {
        this.updateListbox();
    }

    handleBlur() {
        if (this.hasHoverWithin || this.activeIndex > 0 || this.formHasHover) {
            return;
        }
        this.closeListbox();
        this.input.value = '';
        this.inputClear.hideButton();
        // Point 7 in
        // eslint-disable-next-line
        // https://confluence.ontrq.com/pages/viewpage.action?spaceKey=TOYSR&title=TOYSR+-+FSD+-+Package+1+-+Global+Header#TOYSR-FSD-Package1-GlobalHeader-Behaviorsandflows.7
    }

    handleFormMouseenter() {
        this.formHasHover = true;
    }

    handleFormMouseleave() {
        this.formHasHover = false;
    }

    handleClose() {
        this.closeListbox();
    }

    toggleSpinner(isShown) {
        const content = this.listbox.firstElementChild;

        if (isShown) {
            if (content) {
                content.classList.add('m-loading');
                content.setAttribute('aria-busy', 'true');
            }
            this.toggleOverlay(true);
        } else {
            if (content) {
                content.classList.remove('m-loading');
                content.setAttribute('aria-busy', 'false');
            }
            this.toggleOverlay(false);
        }
    }

    toggleOverlay(isShown) {
        const overlayStyles = this.suggestionsOvelay.style;
        this.flyoutMinHeight = this.suggestionsOvelay.offsetParent
            ? this.suggestionsOvelay.offsetParent.clientHeight : this.flyoutMinHeight;
        const closeSearch = () => this.closeListbox();
        const isAsPopup = window.innerWidth < window.styleConstants.breakpoint.small; // on mobile popup provide overlay
        var body = $('body');
        var html = $('html');
        var page = $('.b-page_inner');
        this.toggleBarcodeBtn();

        if (!isShown) {
            overlayStyles.opacity = 0;
            overlayStyles.visibility = 'hidden';
            overlayStyles.height = `${this.flyoutMinHeight}px`;
            body.removeClass('no-scroll');
            html.removeClass('no-scroll');
            page.removeClass('no-scroll');

            if (!isAsPopup) {
                window.partialOverlay.close();
            }
        } else {
            const flyoutBorderHeight = 4;
            const suggestionsHeight = this.listbox.firstElementChild
                ? this.listbox.firstElementChild.clientHeight : 30;

            overlayStyles.opacity = 1;
            overlayStyles.visibility = 'visible';
            overlayStyles.height = `${suggestionsHeight + flyoutBorderHeight + this.flyoutMinHeight}px`;

            if (!isAsPopup) {
                window.partialOverlay.open(closeSearch);
            } else {
                body.addClass('no-scroll');
                html.addClass('no-scroll');
                page.addClass('no-scroll');
            }
        }
    }

    toggleBarcodeBtn() {
        if (this.input) {
            const wrapper = this.input.closest('#header-search-area');
            const barcodeBtn = wrapper && wrapper.querySelector('#scanner_button');
            const valueLength = this.input.value.length;

            if (barcodeBtn && valueLength >= 3) {
                barcodeBtn.classList.add('js-hide-btn');
            } else if (barcodeBtn) {
                barcodeBtn.classList.remove('js-hide-btn');
            }
        }
    }

    afterItemActivated(item) {
        const suggestions = this.listbox.querySelector('[data-js-combo-listbox-content]');
        if (typeof suggestions.scrollTo === 'function') {
            suggestions.scrollTo({
                top: item.offsetTop,
                left: 0,
                behavior: 'smooth'
            });
        }
    }

    initClearButton() {
        const InputClear = require('./InputClear');

        this.inputClear = new InputClear(this.clearButton);
        this.inputClear.init();
    }
};
