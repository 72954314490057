const validation = require('./clientSideValidation').functions;

const SELECTORS = {
    modal: '#newsletterModal',
    form: 'form',
    email: '#newsletterEmail',
    closeBtn: '.js-modal-dialog-close',
    signupBtn: '.js-newsletter-signup',
    thanks: '.js-newsletter-modal-thanks'
};

const $modal = $(SELECTORS.modal);
const $form = $modal.find(SELECTORS.form);
const $closeBtn = $modal.find(SELECTORS.closeBtn);
const $signupBtn = $modal.find(SELECTORS.signupBtn);
const $thanks = $modal.find(SELECTORS.thanks);

function showNewsletterModal(selector) {
    window.dialogManager.openDialog('modal', selector);
}

function closeNewsletterModal(selector) {
    window.dialogManager.closeDialog(selector);
}

function displayMessage(data) {
    if (data.success) {
        $thanks.removeClass('h-hidden');
        $form.addClass('h-hidden');
    }
}

module.exports = () => {
    $form.on('submit', (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();

        if (validation.validateForm($form)) {
            validation.clearForm($form);

            $form.spinner().start();
            $signupBtn.attr('disabled', true);

            const url = $form.attr('action');

            $.ajax({
                url,
                type: 'post',
                dataType: 'json',
                data: $form.serialize()
            })
                .done((data) => {
                    displayMessage(data);
                })
                .fail(() => {
                    // do nothing
                })
                .always(() => {
                    $signupBtn.attr('disabled', false);
                    $form.spinner().stop();
                });
        }
    });

    $thanks.on('click', () => {
        closeNewsletterModal(SELECTORS.modal);
    });

    $closeBtn.on('click', () => {
        closeNewsletterModal(SELECTORS.modal);
    });

    $(document).ready(() => {
        if ($modal.length) {
            showNewsletterModal(SELECTORS.modal);
        }
    });
};
