let lastBreakpoint;
let calls = [];

const getBreakpoint = () => {
    let breakpoint;

    switch (true) {
        case window.innerWidth < window.styleConstants.breakpoint.small:
            breakpoint = 'small';
            break;
        case (window.innerWidth >= window.styleConstants.breakpoint.small && window.innerWidth < window.styleConstants.breakpoint.medium):
            breakpoint = 'medium';
            break;
        case window.innerWidth >= window.styleConstants.breakpoint.medium:
            breakpoint = 'large';
            break;
    }

    return breakpoint;
};

module.exports = {
    getBreakpoint,
    listenBreakpointChange: function (callback) {
        calls.push(callback);
        window.addEventListener('resize', () => {
            const currentBreakpoint = getBreakpoint();
            if (currentBreakpoint === lastBreakpoint) {
                return;
            }
            lastBreakpoint = currentBreakpoint;
            calls.forEach(call => call());
        });
    }
};
