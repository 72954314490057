const timeoutTime = 1485000; // 25min

let timer;
const $sessionLogout = $('.js-logged-in-user');
function showSessionPopup() {
    let $modalContainer = $('js-time-out-modal');
    if (!$modalContainer.length) {
        $modalContainer = $($sessionLogout.html());
        $('body').append($modalContainer);
    }

    window.dialogManager.openDialog('modal', '#sessionTimeOutModal', document.activeElement || document.body);

    let secondsLeft = 15;
    const $timeouCountdown = $modalContainer.find('.js-session-timeout_countdown');
    const leftTimer = setInterval(() => {
        secondsLeft--;
        $timeouCountdown.text(secondsLeft);

        if (secondsLeft <= 0) {
            window.location.assign(
                $sessionLogout.data('logoutUrl') ||
                $modalContainer.find('.js-session-logout').attr('href')
            );
            clearInterval(leftTimer);
        }
    }, 1000);

    $modalContainer.one('click', '.js-session-keep', function () {
        window.dialogManager.closeDialog();
        timer = setTimeout(showSessionPopup, timeoutTime);
        clearInterval(leftTimer);
        $modalContainer.remove();

        $.get($(this).data('keepUrl')); // we don't care about response
    });
}
module.exports = () => {
    if ($sessionLogout.length) {
        timer = setTimeout(showSessionPopup, timeoutTime);

        $(document).ajaxStop(() => { // update timeout if user is active
            if (timer) {
                clearTimeout(timer);
            }
            timer = setTimeout(showSessionPopup, timeoutTime);
        });
    }
};
