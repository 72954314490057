/**
 * Create notification for applied changes
 *
 * @param {string} message - message text
 */
module.exports = function waiNotify(message) {
    const $notification = $('.js-notifier');
    if ($notification && $notification[0].widget) {
        $notification[0].widget.notify(message);
    }
};
