module.exports = function () {
    const $imagesSection = $('.js-images-section');
    const $productImages = $('.js-product-images-slick');
    const $productImagesSet = $('.js-product-images-slick-set');
    const $headerCarousel = $('.js-header-banner-carousel');
    const $CategoryCarousel = $('.js-category-carousel').data('slick-configuration');
    let mediumBreakPoints = window.styleConstants.breakpoint.medium;
    let smallBreakPoints = window.styleConstants.breakpoint.small;

    function setAvailabilityMessage($parentElement) {
        const $carousel = $parentElement ? $parentElement : $('.js-product-recommendations, .js-category-carousel');
        const $tileContainer = $carousel.find('.b-product_tile-inner');
        $tileContainer.each(function() {
            var $availabilityMsgBlock = $(this).find('.js-product_tile-availability_msg');
            var $shippingInfoBlock = $(this).find('.js-shipping-info');
            $availabilityMsgBlock.removeClass().addClass('b-product_tile-availability_msg b-availability_msg js-product_tile-availability_msg');
            var isShipToHome = $shippingInfoBlock.find('.js-homeDelivery').hasClass('m-available');
            var isFreeISPU = $shippingInfoBlock.find('.js-freeInStorePickup').hasClass('m-available');
            var availabilityText = $shippingInfoBlock.data('availabilitytext');
            var availabilityType = $shippingInfoBlock.data('availabilitytype');
            var comingSoon = $shippingInfoBlock.data('comingsoon');
            var soldout = $shippingInfoBlock.data('soldout');

            if (isShipToHome && availabilityType !== 'preorder') {
                $availabilityMsgBlock.addClass('m-instock');
            } else if (isFreeISPU && !isShipToHome) {
                $availabilityMsgBlock.addClass('m-pickuponly');
                $availabilityMsgBlock.text(availabilityText);
            } else if (!isFreeISPU && !isShipToHome && !comingSoon) {
                $availabilityMsgBlock.addClass('m-outofstock');
                $availabilityMsgBlock.text(availabilityText);
                if (availabilityType === 'instoreonly') {
                    $availabilityMsgBlock.removeClass('m-outofstock').addClass('m-instoreonly');
                }
            } else if (availabilityType === 'comingsoon' && comingSoon) {
                $availabilityMsgBlock.addClass('m-comming_soon');
                $availabilityMsgBlock.text(availabilityText);
            } else if (availabilityType === 'soldout' && soldout) {
                $availabilityMsgBlock.addClass('m-sold_out');
                $availabilityMsgBlock.text(availabilityText);
            } else if (availabilityType === 'outofstock') {
                $availabilityMsgBlock.text(availabilityText);
            } else {
                $availabilityMsgBlock.addClass('m-' + availabilityType);
                $availabilityMsgBlock.text(availabilityText);
            }
            $(this).removeClass('js-availability-msg-update');
        });
    }

    function setHeartIconForRecommendation($parentElement) {
        let wishListItems = $('.js-wishlist-items').data('items');
        if (wishListItems) {
            let itemsArr = wishListItems.split(',');
            let $productRecommendations = $parentElement ? $parentElement : $('.js-product-recommendations');
            let icons = $productRecommendations
                .find('.js-wishlistTile')
                .toArray();

            itemsArr.forEach((item) => {
                icons.forEach((icon) => {
                    if (item === $(icon).data('pid')) {
                        $(icon).addClass('b-product_wishlist-registered');
                    }
                });
            });
        }
    }

    $productImages.slick({
        slidesToShow: 1,
        dots: false,
        asNavFor: '.js-product-thumbnails',
        swipe: false,
        responsive: [
            {
                breakpoint: mediumBreakPoints,
                settings: {
                    slidesToShow: 1,
                    dots: true,
                    swipe: true
                }
            }
        ]
    });

    $productImagesSet.slick({
        slidesToShow: 1,
        dots: false,
        asNavFor: '.js-product-thumbnails-set',
        swipe: false,
        responsive: [
            {
                breakpoint: smallBreakPoints,
                settings: {
                    slidesToShow: 1,
                    dots: true
                }
            }
        ]
    });

    $('.js-product-thumbnails').slick({
        slidesToShow: 7,
        slidesToScroll: 1,
        asNavFor: '.js-product-images-slick',
        focusOnSelect: true,
        vertical: true,
        verticalSwiping: true,
        arrows: false,
        responsive: [
            {
                breakpoint: mediumBreakPoints,
                settings: {
                    slidesToShow: 4
                }
            }
        ]
    });

    $('.js-product-thumbnails-set').slick({
        slidesToShow: 7,
        slidesToScroll: 1,
        asNavFor: '.js-product-images-slick-set',
        focusOnSelect: true,
        arrows: false,
        responsive: [
            {
                breakpoint: smallBreakPoints,
                settings: {
                    slidesToShow: 4
                }
            }
        ]
    });
    const productRecommendationConfig = {
        slidesToShow: 4,
        slidesToScroll: 4,

        responsive: [
            {
                breakpoint: mediumBreakPoints,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }
        ]
    };

    function init() {
        $('.js-product-recommendations').filter(':not(.js-experience)').slick(productRecommendationConfig);
        $('.js-category-carousel').slick($CategoryCarousel);
        $('.js-carousel').slick();
        $('.js-hero-page-carousel').slick();
        $('.js-product-asset-carousel').slick();
        $headerCarousel.slick();
    }

    $(document).on('recommendation.init', (e, data) => {
        const $productRecommendations = (data && data.parentElement) ? data.parentElement : $('.js-product-recommendations');
        const $categoryCarousel = (data && data.parentElement) ? data.parentElement : $('.js-category-carousel');

        $productRecommendations.find('.js-add-to-cart-btn').remove();
        $productRecommendations.filter(':not(.slick-initialized)').slick(productRecommendationConfig);

        $categoryCarousel.filter(':not(.slick-initialized)').slick($CategoryCarousel);

        setHeartIconForRecommendation((data && data.parentElement) ? data.parentElement : null);
        setAvailabilityMessage((data && data.parentElement) ? data.parentElement : null);
    });

    $headerCarousel.on('afterChange', () => {
        var scrollCount = parseInt($headerCarousel.data('scrollCount'));
        if (scrollCount === 0) {
            $headerCarousel.slick('slickPause');
        } else if (scrollCount === -1) {
            $headerCarousel.off('afterChange');
        } else {
            $headerCarousel.data('scrollCount', scrollCount - 1);
        }
    });

    $($imagesSection).on('slideUpdated', () => {
        if ($imagesSection.data('slide-index') !== $productImages.slick('slickCurrentSlide')) {
            $('.js-product-images').slick('slickGoTo', $imagesSection.data('slide-index'));
        }
    });

    $productImages.on('afterChange', (event, slick, currentSlide) => {
        $imagesSection
            .data('slide-index', currentSlide)
            .trigger('slideUpdated');
    });

    if ($(window).width() > mediumBreakPoints) {
        $('.js-zoom').css('display', 'block').parent().zoom({ magnify: 1.5 });
    }

    if ((document.readyState === 'complete') || (document.readyState === 'interactive')) {
        init();
    } else {
        document.onreadystatechange = function () {
            if ((document.readyState === 'complete') || (document.readyState === 'interactive')) {
                init();
            }
        };
    }
};
