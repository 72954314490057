const pageHeaderBanner = {
    init() {
        this.headerBanner = document.body.querySelector('.header-banner');

        if (!this.headerBanner || sessionStorage.getItem('headerBanner')) {
            return;
        }

        this.closeButton = this.headerBanner.querySelector('.close-button');
        this.content = this.headerBanner.querySelector('.html-slot-container');

        if (this.content && this.content.children.length) {
            this.events();
            this.setStyles();
        }
    },

    events() {
        if (this.closeButton) {
            this.closeButton.addEventListener('click', this.closeBanner.bind(this));
        }
    },

    setStyles() {
        this.headerBanner.classList.add('js-show');
        this.headerBanner.style.height = `${this.headerBanner.offsetHeight}px`;
    },

    removeShowClass() {
        this.headerBanner.classList.remove('js-show');
        this.removeEventListener.removeEventListener('transitionend', this.removeShowClass);
    },

    closeBanner() {
        this.headerBanner.style = 'display: none';
        this.headerBanner.addEventListener('transitionend', this.removeShowClass);

        sessionStorage.setItem('headerBanner', '1');
    }
};

module.exports = function() {
    pageHeaderBanner.init();
};
