module.exports = function () {
    $(document).on('click', '.js-switch-locale-button', (e) => {
        e.preventDefault();

        const $link = $(e.target);
        const $page = $('.js-page');
        const action = $page.data('action');
        var queryString = $page.data('querystring');
        var hashPresent = queryString.split('#')[1];
        if (!hashPresent && window.location.href.split('#')[1]) {
            queryString += '#' + window.location.href.split('#')[1];
        }

        const localeCode = $link.data('locale');
        const localeCurrencyCode = $link.data('currencycode');
        const url = $('.js-country_selector').data('url');

        $.ajax({
            url,
            type: 'get',
            dataType: 'json',
            data: {
                code: localeCode,
                queryString,
                CurrencyCode: localeCurrencyCode,
                action
            },
            success(response) {
                if (response && response.redirectUrl) {
                    window.window.location.href = response.redirectUrl;
                }
            },
            error() {}
        });
    });
};
