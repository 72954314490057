module.exports = () => {
    var $brandSwithcDelayEl = $('[data-brand-switch-delay]');

    if ($brandSwithcDelayEl.length > 0) {
        var $brandSwitch = $brandSwithcDelayEl.find('.b-utility_domain_switcher-link');

        $brandSwitch.on('click', function (e) {
            e.preventDefault();

            var brandSwitchDelay = Number($brandSwithcDelayEl.data('brandSwitchDelay'));
            var url = $(this).attr('href');

            setTimeout(() => {
                window.location.href = url;
            }, brandSwitchDelay); // set delay in order for SFCC to have enough time switch session.custom.currentBrand in customer groups
        });
    }
};

