const CompositedComponent = require('./utils/CompositedComponent');

module.exports = function () {
    const menuList = document.querySelector('[data-js-nav]');
    const menuBar = document.querySelector('[data-js-menu-bar]');
    const newNav = document.querySelectorAll('.js-new-navigation-menu');

    if (menuList && menuBar) {
        const Menubar = require('./menus/Menubar');

        if (newNav.length > 0) {
            $('.b-header_button.m-first').addClass('no-border');
            const HamburgerNew = require('./menusV2/HamburgerMenuV2');
            new CompositedComponent(new HamburgerNew(menuList), new Menubar(menuBar));
        } else {
            const Hamburger = require('./menus/HamburgerMenu');
            new CompositedComponent(new Hamburger(menuList), window.styleConstants.breakpoint.medium, new Menubar(menuBar));
        }
    }
};
