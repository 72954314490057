const recaptcha = $('#js-g-recaptcha');
const cbError = $('.cb-error-message');
const recaptchaFeedback = $('.js-g-recaptcha-feedback');

function init() {
    this.reCaptchaSuccess = false;
    const verifyCallback = () => {
        this.reCaptchaSuccess = true;
        recaptchaFeedback.hide();
    };
    const expiredCallback = () => {
        setTimeout(() => {
            window.grecaptcha.reset();
            this.reCaptchaSuccess = false;
        }, 0); // adds recaptcha reset to the end of queue
    };
    window.onloadCallback = function () {
        if (recaptcha.html() === '') {
            window.grecaptcha.render(recaptcha[0], {
                sitekey: recaptcha.data('sitekey'),
                callback: verifyCallback,
                'expired-callback': expiredCallback
            });
        }
    };

    cbError.hide();
    recaptchaFeedback.hide();
    const locale = $('.js-locale').data('locale').replace('_', '-');
    const scriptTag = document.createElement('script');
    scriptTag.setAttribute('src', '//www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit&hl=' + locale);
    document.getElementsByTagName('body')[0].appendChild(scriptTag);
}

function modalInit() {
    let modalRecaptcha = $('#js-login-modal').find('#js-g-recaptcha');

    this.reCaptchaSuccess = false;
    const verifyCallback = () => {
        this.reCaptchaSuccess = true;
        console.log('test8');
        $('.js-g-recaptcha-feedback').hide();
    };
    const expiredCallback = () => {
        setTimeout(() => {
            window.grecaptcha.reset();
            this.reCaptchaSuccess = false;
        }, 0);
    };

    if (modalRecaptcha.html() === '') {
        let recaptchaRef = window.grecaptcha.render(modalRecaptcha[0], {
            sitekey: modalRecaptcha.data('sitekey'),
            callback: verifyCallback,
            'expired-callback': expiredCallback
        });

        modalRecaptcha.data('recaptcha-ref', recaptchaRef);
    }

    cbError.hide();
    $('.js-g-recaptcha-feedback').hide();
    const locale = $('.js-locale').data('locale').replace('_', '-');
    const scriptTag = document.createElement('script');
    scriptTag.setAttribute('src', '//www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit&hl=' + locale);
    document.getElementsByTagName('body')[0].appendChild(scriptTag);
}

function checkReCaptchaStatus() {
    $('.js-g-recaptcha-feedback').hide();
    if (!this.reCaptchaSuccess) {
        $('.js-g-recaptcha-feedback').show();
        return false;
    }
    return true;
}

function cleanErrorMessage() {
    $('.cb-error-message-text').text('');
    $('.cb-error-message').hide();
}

function isCaptchaEnable() {
    return $('#js-g-recaptcha').first().data('enable') === true;
}

function resetCaptcha() {
    if (window.grecaptcha) {
        window.grecaptcha.reset();
    }
}

module.exports = {
    init: init,
    modalInit: modalInit,
    checkReCaptchaStatus: checkReCaptchaStatus,
    cleanErrorMessage: cleanErrorMessage,
    isCaptchaEnable: isCaptchaEnable,
    resetCaptcha: resetCaptcha
};
