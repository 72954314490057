const CompositedComponent = require('./utils/CompositedComponent');
const Grid = require('./a11y/Grid');

class FooterGrid {
    constructor(domNode) {
        this.container = domNode;
        this.grids = [];
    }

    init() {
        if (this.grids.length) {
            this.grids.forEach(grid => grid.init());
            return;
        }

        this.container.querySelectorAll('[data-role=grid]').forEach(grid => {
            const column = new Grid(grid);
            this.grids.push(column);
            column.init();
        });
    }

    destroy() {
        this.grids.forEach(grid => grid.destroy());
    }
}

module.exports = function () {
    const footerNav = document.querySelector('[data-js-footer-nav]');

    if (!footerNav) {
        return;
    }

    const Accordion = require('./togglers/Accordion');

    new CompositedComponent(new Accordion(footerNav), window.styleConstants.breakpoint.small, new FooterGrid(footerNav));
};
