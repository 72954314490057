var formValidation = require('../components/formValidation');

function displayMessage() {
    var $initalState = $('#rooms-initial-signup-form');
    var $finalState = $('#post-signup-form');

    $initalState.hide();
    $finalState.show();
}

module.exports = {
    submitSubscription () {
        $('body').on('submit', '.js-subscription-form', (e) => {
            e.preventDefault();

            var $form = $(e.currentTarget);
            $form.spinner().start();
            const url = $form.attr('action');

            $.ajax({
                url,
                type: 'post',
                dataType: 'json',
                data: $form.serialize()
            }).done((data) => {
                if (!data.success) {
                    formValidation($form, data);
                } else {
                    displayMessage();
                }
                $form.spinner().stop();
            });
        });
    }
};
